

span.filter > img:first-child {
    margin: 0 15px 0 5px;
    padding-bottom: 5px;
}

span.filter > img:last-child {
    padding-bottom: 4px;
    margin-left: 20px;
}