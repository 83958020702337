.theme-select {
    position: relative;
}

.theme-select img {
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.theme-select select {
    width: 100%;
    background-color: var(--white-color) !important;
    padding: 10px 25px 12px 12px;
    font-size: 17px;
    font-weight: bold;
    line-height: 1.24;
    cursor: pointer;
    letter-spacing: 0.4px;
    border: 1px solid var(--primary-cloudy-blue-color);
    box-shadow: 1px 1.8px 2px 0 var(--shadow-color);
    -webkit-appearance: none;
    height: 45px;
}

.theme-select svg {
    position: absolute;
    right: 10px;
    top: calc(50% - 7px);   
}

.theme-select select:focus {
    outline: none;
}

.theme-select select:disabled {
    cursor: not-allowed;
    color: var(--secondary-grey-light-color);
    background:
            repeating-linear-gradient( -45deg, var(--disabled-background-gradient-start-color), var(--disabled-background-gradient-start-color)),
            repeating-linear-gradient(-45deg, var(--disabled-background-gradient-end-color), var(--disabled-background-gradient-end-color), 2px, transparent 4px, transparent 2px) !important;
    background-blend-mode: overlay;
    border: solid 1px var(--primary-cloudy-blue-color) !important;
}