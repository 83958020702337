.Panel {
    box-shadow: 1px 1.7px 2px 0 var(--shadow-color);
    background-color: var(--secondary-white-color);
    border: solid 1px var(--primary-cloudy-blue-color);
    padding: 50px;
    margin-bottom: 50px;
}

.PanelHeader > .row > div > h3 > img {
    width: 30px;
    margin-right: 10px;
}

.PanelHeader.fill {
    margin-top: -30px;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 20px;
    vertical-align: middle !important;
    background-color: var(--secondary-grey-lightest);
}

.Panel > .row {
    margin-top: 20px;
}

.Panel > .PanelHeader > .row {
    cursor: default;
    margin-top: 0;
}

.Panel > .PanelHeader > .row.draggable {
    cursor: inherit;
}

.Panel > .PanelHeader > .row > div > .icon {
    margin: 10px 10px 5px 0;
    cursor: pointer;
    display: inline-block;
    width: 30px;
    height: 30px;
}

.Panel > .PanelHeader > .row > div > .icon.remove {
    background: var(--primary-color) url('./images/ico-delete.svg') no-repeat center center ;
    background-size: 20px 20px;
}

.Panel > .PanelHeader > .row > div > .icon.edit {
    background: var(--primary-color) url('./images/ico-edit.svg') no-repeat center center ;
    background-size: 20px 20px;
}

@media all and (max-width:991px) {

    .PanelHeader.fill {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        padding-left: 20px;
        margin-bottom: 10px;
    }
    
}