.Sidebar {
    display: table-cell;
    vertical-align: top;
    position: relative;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    height: calc(100vh - 180px);
    min-height: 100%;
    background-color: var(--primary-color);
    font-size: 15px;
    font-weight: bold;
    font-stretch: condensed;
    line-height: 1.33;
    letter-spacing: 1.1px;
    color: var(--white-color);
}

.Sidebar .main-menu,
.Sidebar .main-menu div {
    position: relative;
}

.Sidebar .main-menu {
    padding-top: 40px;
}

.Sidebar .main-menu div {
    padding: 12px 30px;
}

.Sidebar .main-menu div:after,
.Sidebar .main-menu.collapsed-with-title:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 30px;
    border-bottom: 1px solid var(--gray-lightest-color);
    width: 240px;
}

.Sidebar .main-menu.collapsed-with-title:after {
    border-bottom: none;
}

.Sidebar .main-menu.collapsed-with-title {
    padding-bottom: 25px;
}

.Sidebar .main-menu.collapsed-without-title:after {
    content: none;
}

.Sidebar .main-menu div:hover,
.Sidebar .main-menu img:hover {
    cursor: pointer;
}

.Sidebar .main-menu div:hover {
    background-color: var(--primary-dark-color);
}

.Sidebar .main-menu img {
    width: 18px;
    margin-right: 10px;
    padding-bottom: 5px;
}

.Sidebar .menu-title {
    font-size: 17px;
    font-weight: 300;
    line-height: 1.24;
    letter-spacing: 0.4px;
    padding: 40px 30px;
}

.Sidebar .menu-title span {
    display: block;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.19;
    letter-spacing: 0.8px;
}

.Sidebar .backoffice-options {
    position: absolute;
    bottom: 0;
    padding: 20px 30px 25px;
}

.Sidebar .backoffice-options a {
    color: var(--white-color);
}

.Sidebar .backoffice-options a:hover,
.Sidebar .backoffice-options a:active {
    text-decoration: none;
}

.Sidebar .backoffice-options:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 30px;
    border-top: 1px solid var(--gray-lightest-color);
    width: 240px;
}

.Sidebar .backoffice-options img {
    width: 21px;
    margin-right: 10px;
    padding-bottom: 5px;
}

.closeSidebar {
    display: none;
}

.openSidebar {
    animation-name: openSidebar;
    animation-fill-mode: forwards;
    animation-duration: 0.1s;
    animation-timing-function: ease-in-out;
}

@keyframes openSidebar {
    0% {
        -webkit-transform: translateX(-300px);
        -moz-transform: translateX(-300px);
        -ms-transform: translateX(-300px);
        -o-transform: translateX(-300px);
        transform: translateX(-300px);
    }
    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

.hide {
    display: none;
}

.collapsed-with-title {
    padding-left: 30px;
    padding-right: 30px;
}

.collapsed-without-title {
    padding-bottom: 30px !important;
}

.Sidebar .main-menu.text-uppercase a {
    color: var(--white-color) !important;
    text-decoration: none;
}

@media (max-width: 991px) {

    .openSidebar {
        position: fixed;
        z-index: 1005;
        left: 0;
        top: 82px;
    }
    .Sidebar .main-menu.collapsed-with-title a {
        font-size: 26px;
        display: block;
    }
    .Sidebar .main-menu.collapsed-with-title.extra-tabs-mobile {
        padding: 12px 25px;
    }
    .Sidebar .main-menu.collapsed-with-title.extra-tabs-mobile:hover {
        background-color: var(--primary-dark-color);
    }
}