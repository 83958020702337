.SimpleTag .custom-property:first-child {
    margin-top: 20px;
}

.SimpleTag .custom-property {
    position: relative;
    width: fit-content;
    padding: 10px 40px 10px 25px;
    border: 2px solid var(--primary-color);
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 10px;
}

.SimpleTag .custom-property img {
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.SimpleTag .custom-property img:hover {
    cursor: pointer;
}

.equal-sign {
    padding: 36px 0 0 !important;
}