/*Theme default layout*/
.Layout {
    overflow-x: hidden;
}

.Layout .wrapper {
    display: table;
    min-height: calc(100vh - 151px);
}

.Layout .Content {
    display: table-cell;
    vertical-align: top;
    padding: 50px;
    width: calc(100% - 300px);
    background-color: var(--primary-ice-blue-color);
}

.Layout .full-width {
    width: 100%;
}

/*Theme dashboard layout*/
.LayoutDashboard .content {
    padding: 40px 15px;
    min-height: calc(100vh - 211px);
}

.blur-background {
    display: block;
    position: fixed;
    left: 0;
    background-color: #0000005c;
    z-index: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
}

@media (max-width: 991px) {

    .Layout .Content {
        display: flex;
        min-height: calc( 100vh - 201px);
        width: 100%;
        padding: 10px;
    }
    .Layout .Content > div {
        width: 100%;
    }
    .LayoutDashboard .content {
        padding-top: 120px !important;
        min-height: calc(100vh - 119px);
    }
    .Layout .wrapper {
        padding-top: 82px !important;
    }
}