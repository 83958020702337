#user-management-menu {
    position: absolute;
    top: 72px;
    right: 0;
    display: flex;
    z-index: 5;
    font-size: 14px;
}
#user-management-menu header {
    height: 143.23px;
    background-color: var(--primary-color);
    padding: 2rem;
}
#user-management-menu nav {
    background-color: var(--white-color);
    position: absolute;
    right: 0;
    top: -0;
    top: 0;
    bottom: 0;
    max-width: 280px;
    width: 280px;
}
#user-management-menu nav .user-details {
    display: none;
    text-transform: uppercase;
    padding-bottom: 0;
    height: 66px;
}
#user-management-menu nav .underline {
    padding: 0 2rem;
    background-color: var(--black-color);
    display: none;
}
#user-management-menu nav .underline hr {
    margin-bottom: 0;
    margin-top: 0;
}
#user-management-menu .user-details,
#user-management-menu nav ul {
    padding: 1.0rem 2rem;
    background-color: var(--black-color);
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 14px;
}
#user-management-menu nav ul li {
    padding-left: 55px;
    margin-bottom: 8px;
    cursor: pointer;
    position: relative;
    transition: font-weight .2s ease;
    color: var(--white-color)
}
#user-management-menu nav ul li a {
    color: var(--white-color) !important;
}
#user-management-menu nav ul li svg path,
#user-management-menu nav ul li svg circle {
    fill: var(--white-color);
}
#user-management-menu nav ul li svg:not(#logout-icon) path,
#user-management-menu nav ul li svg:not(#logout-icon) circle {
    stroke-width: .7px;
}
#user-management-menu nav ul li svg {
    position: absolute;
    left: 10px;
    top: 5px;
}
#user-management-menu.openUserMenu nav {    
    animation-name: openUserMenu;
    animation-fill-mode: forwards;
    animation-duration: 0.1s;
    animation-timing-function: ease-in-out;
}
#user-management-menu #close-icon {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
}
#user-management-menu nav ul li #users-icon {
    width: 31px;
    height: auto;
    left: 8px;
    top: 2px;
}
#user-management-menu .loggedUser-username {
    text-transform: capitalize;
}
#user-management-menu #close-icon line {
    stroke-width: 6px !important;
}
@keyframes openUserMenu {
    0% {
        -webkit-transform: translateX(400px);
        -moz-transform: translateX(400px);
        -ms-transform: translateX(400px);
        -o-transform: translateX(400px);
        transform: translateX(400px);
    }
    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}
@media screen and (max-width: 767px) {
    #user-management-menu nav .underline,
    #user-management-menu nav .user-details {
        display: block;
    }
}