.DeviceManagement .table-options {
    margin-bottom: 30px;
}

.DeviceManagement .table-options .Button button {
    margin-top: 0;
    margin-bottom: 0;
}

.DeviceManagement .table-options .Button:last-child button {
    margin-right: 0;
}

.DeviceManagement .table-options .Button:last-child button img {
    width: 20px;
}

.DeviceManagement .table-pagination {
    margin-bottom: 40px;
}

.DeviceManagement .table-pagination .Button:not(:last-child) {
    margin-right: 10px;
}

.DeviceManagement .table-pagination .Button button,
.DeviceManagement .table-pagination .Button button.current-page {
    padding: 0 !important;
}

.DeviceManagement .table-pagination .Button button.current-page {
    background-color: var(--white-color);
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
}

.DeviceManagement .Search > span > input{
    height: 45px;
}

.DeviceManagement .devices-dropmenu-group {
    padding: 0;
}

.DeviceManagement .devices-dropmenu-group .theme-select select {
    min-width: 70px;
    max-width: 75px;
}

.DeviceManagement .devices-dropmenu-group .theme-select:first-child {
    margin-right: 10px;
}
.DeviceManagement .devices-dropmenu-group .theme-select:last-child select {
    width: 170px;
    min-width: unset;
    max-width: unset;
}

.DeviceManagement .devices-dropmenu-group .theme-select {
    display: inline-block;
}

.QRCodeDiv {
    border: 3px solid var(--white-color);
}

.QRCodeDiv > div > .Alert {
    margin-bottom: 25px;
}

.DeviceManagement .devices-battery-column {
    width: 100px;
    text-align: center;
}

.DeviceManagement .battery-cell {
    text-align: center;
}

.DeviceManagement #battery-icon {
    width: 18px;
}

.DeviceManagement .devices-appVersion-column {
    width: 150px;
    text-align: center;
}

.DeviceManagement .appVersion-cell {
    text-align: center;
}

.modal-body .battery.device-battery {
    display: inline-block;
}

.modal-body .battery.device-battery.vertical {
    margin-right: 10px;
    transform: rotateZ(270deg);
}

.primary-color span {
    color: var(--primary-color);
}

.technical-info > label {
    cursor: pointer;
}

.technical-info.reduced #single-arrows {
    transform: rotateZ(180deg);
    transition: all .3s cubic-bezier(0.82, 0, 0.06, 1.01);
}
.technical-info.expanded #single-arrows {
    transform: rotateZ(0);
    transition: all .3s cubic-bezier(0.82, 0, 0.06, 1.01);
}

.technical-info.reduced .technical-info-list {
    height: 0;
    overflow: hidden;
}

.technical-info.expanded .technical-info-list {
    height: auto;
    overflow: unset;
}

.readonly .readonly-value {
    overflow: hidden;
    max-width: calc(100% - 80px);
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.modal-body .custome-label {
    font-size: 12px;
    color: rgba(0, 0, 0, .6);
    font-weight: 400;
}

.readonly .copied {
    height: 24px;
    display: inline-block;
    margin-left: 15px;
}

.readonly svg {
    cursor: pointer;
    margin-left: 10px;
    display: inline-block;
}

@media all and (max-width:991px) {
    .DeviceManagement .table-options {
        margin-bottom: 15px;
    }
    .DeviceManagement table th:nth-child(2),
    .DeviceManagement table th:nth-child(3),
    .DeviceManagement table th:nth-child(4),
    .DeviceManagement table th:nth-child(5),
    .DeviceManagement table th:nth-child(6) {
        display: none;;
    }
    .modal-dialog .modal-content .modal-footer {
        display: block;
    }
    .modal-dialog .modal-content .modal-footer .Button {
        display: inline-block;
        width: 45%;
    }
    .modal-dialog .modal-content .modal-footer .Button button {
        display: block;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        padding-left: 1em !important;
        padding-right: 1em !important;
    }
    .Button > button > .text {
        line-height: inherit;
    }
    .Button > button.add > .text:after {
        background: url(../../themes/default/Form/images/ico-more.svg)  no-repeat scroll 10px 2px;
    }
    .modal-body .QRCodeDiv {
        margin-left: 0;
        margin-right: 0;
        padding: 10px;
    }
}

@media screen and (max-width: 767px) {
    .DeviceManagement .devices-dropmenu-group {
        padding: 0 12px;
    }
}