.VenueDashboard .Panel .row {
    margin-bottom: 0 !important;
}

.VenueDashboard .Panel .PanelHeader {
    margin-bottom: 50px !important;
}

.VenueDashboard > .Scene > .title {
    font-size: 36px;
}