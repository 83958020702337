.devices > .checkpoint {
  border: 2px dashed grey;
  background-color: lightgray;
  padding: 5px 15px;
  min-height: 150px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.QRCodeDiv .qrcode-text {
  font-size: 30px;
  font-weight: 500;
}

#device_list {
  max-height: 300px;
  overflow-y: scroll;
}

.copy-device-QRCode {
  line-height: 33px;
}

.copy-device-QRCode .copied {
  color: var(--highlight-kermit-green-color);
}

.copy-device-QRCode span {
  display: block;
  cursor: pointer;
}

.copy-device-QRCode span svg {
  margin-left: 25px;
  width: 28px;
}
