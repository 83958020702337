.key .tag-filter-labels,
.value .tag-filter-labels {
    display: block;
    font-weight: bold;
}

.operation {
    margin-top: 20px;
}

.helper {
    visibility: hidden;
}

.Rule {
    margin-top: 30px;
}

.Rule .filters {
    overflow-x: auto;
}

.Rule .filters::-webkit-scrollbar {
    height: 0.5em;
}

.Rule .filters::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--secondary-grey-lightest);
}

.Rule .filters::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--primary-color);
}

.Rule .filters .filters-row {
    display: inline-block;
    padding: 40px 0;
    white-space: nowrap;
}

.Rule .filters div:last-of-type .filters-row {
    border-bottom: 1px dashed var(--gray-light-color);
}

.Rule .filters .filter {
    height: 45px;
    background-color: var(--white-color);
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    padding: 15px;
    border-radius: 30px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0.4px;
}

.Rule .filters img:hover {
    cursor: pointer;
}

.Rule .filters .or,
.Rule .filters .and {
    background-color: var(--primary-color);
    padding: 13px 20px;
    color: var(--white-color);
    font-weight: bold;
}

.Rule .filters .or:hover,
.Rule .filters .and:hover {
    cursor: pointer;
}

.Rule .filters .and-between,
.Rule .filters .or-between {
    background-color: var(--white-color);
    padding: 13px 20px;
    color: var(--black-color);
    font-weight: bold;
}

.Rule .filters .or-between {
    display: block;
    padding: 0 20px;
}

.Rule .filters .or {
    display: inline-block;
    width: 104px;
    margin: 30px 0;
}

.Rule .filters .or img,
.Rule .filters .and img {
    padding-bottom: 5px;
    margin-left: 10px;
    height: 20px;
}

.Rule .filters .or img {
    margin-left: 8px;
}
.Rule .filters .or:hover, 
.Rule .filters .and:hover {
    background-color: var(--primary-dark-color);
}

.Rule .filters .and {
    margin-left: 15px;
}

.modal-header {
    border-bottom: none !important;
}

.modal-content .modal-body {
    padding-bottom: 0;
}

.modal-content .modal-body .key .theme-select,
.modal-content .modal-body .operation .theme-select,
.modal-content .modal-body .value .Input {
    font-weight: 800;
    font-size: 16px;
}

.modal-content .modal-body .key .theme-select,
.modal-content .modal-body .key .tag-filter-labels,
.modal-content .modal-body .value .Input,
.modal-content .modal-body .value .tag-filter-labels {
    display: inline-block;
}

.modal-content .modal-body .value .tag-filter-labels,
.modal-content .modal-body .key .tag-filter-labels {
    margin-right: 25px;
    font-weight: 300;
}

.modal-content .modal-body .value .Input input {
    margin-bottom: 0;
}

.modal-footer {
    border-top: none !important;
    padding-top: 0 !important;
}

.tag-dialog {
    padding-left: 0 !important;
    padding-right: 0 !important;
}


.modal-dialog .modal-header h1 {
    font-size: 22px
}
.tag-dialog {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.tag-dialog .key .theme-select,
.modal-content .modal-body .value .Input,
.tag-dialog label {
    width: 100% !important;
    display: block !important;
}
.tag-dialog .key,
.tag-dialog .operation,
.tag-dialog .value {
    width: 100% !important;
    padding: 0 !important;
    display: block;
    margin: 10px 0;
}

.tag-dialog .operation > div {
    width: 70px !important;
    margin: 10px 0 0 0 !important;
}

.modal-content .modal-body .value {
    position: relative;
}

#auto-complete ul {
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 15px !important;
    background-color: #fff;
    border: 1px solid #c6c7c8;
    border-top: 0;
    z-index: 5;
}

#auto-complete li {
    cursor: pointer;
    list-style: none;
    padding: 5px;
    background-color: #fff;
}

#auto-complete li:hover {
    background-color: var(--primary-color);
    color: #fff;
}