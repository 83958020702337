.LogPanel {
    padding: 5px 0px !important;
    background-color: var(--white-color);
    border: 1px solid var(--secondary-grey-lightest);
    border-bottom: 0;
}

.LogPanel:nth-child(2) {
    border-top: 0;
}

.LogPanel:last-child {
    border-bottom: 1px solid var(--secondary-grey-lightest);
}

.LogPanel > .ticket-info div:first-child .Button button {
    margin: 0;
    width: auto;
    height: auto;
    padding: 1px 10px;
}

.LogPanel > .ticket-info > div:not(:first-child) {
    line-height: 41px;
}

.LogPanel .ticket-info > div.ticket-id {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

.LogPanel .checkpoint-logs {
    height: auto;
    transition: .2s;
    overflow: hidden;
    margin-top: 15px;
}

.LogPanel .checkpoint-logs.collapsed {
    height: 0;
    margin: 0;
}

.LogPanel .checkpoint-log {
    padding: 15px 0 !important;
    border-top: 1px solid var(--secondary-grey-lightest);
}

.LogPanel .checkpoint-log .access-denied {
    color: var(--red-color);
}

.LogPanel .ticket-info > .col-1 {
    padding-top: 8px;
}

.LogPanel .ticket-info .validity {
    text-transform: capitalize;
}
.LogPanel {
    min-width: 850px;
}
.LogPanel .externalTicketId {
    overflow: auto
}