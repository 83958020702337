.Loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
}

.Loading svg {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 80px;
}

.Loading svg * {
    fill: var(--primary-color);
}

/* Loading for tables */
@import  url(https://fonts.googleapis.com/css?family=Montserrat);

.loading-for-tables .text {
  color: var(--primary-color);
  display: inline-block;
  margin-left: 15px;
  letter-spacing: 4px;
}

.loading-for-tables .bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: 15px;
}
.loading-for-tables .bounceball:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--primary-color);
    transform-origin: 50%;
    animation: bounce 500ms alternate infinite ease;
}

@keyframes bounce {
  0% {
    top: 30px;;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}