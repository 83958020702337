:root {
    /* Override the bootstrap variables */
    --bs-body-font-size: 14px !important;

    /* Override the toast variables */
    --toastify-toast-min-height: 100px;
    --toastify-color-light: #f8f8f8;
    --toastify-color-dark: #272727;
    --toastify-color-info: #3498db;
    --toastify-color-success: #03b309;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #ee3520;
}

/* START Override the bootstrap styling */
/* ==================================== */
.modal-backdrop.fade {
    opacity: 0.5 !important;
}
.fade.modal.show {
    opacity: 1;
    padding: 0;
    height: 100vh !important;
}
ol, ul {
    padding-left: 0 !important;
}
.table-hover tbody tr:hover {
    background-color: #f0f0f0;
}
@media (min-width: 768px) {
    .modal-dialog {
        width: 650px !important;
    }
}
/* END Override the bootstrap styling */


/* START Override the toast styling */
/* ================================ */
.Toastify > div,
.Toastify__toast-theme--light {
    width: 450px;
    border-radius: 0!important;
}
.Toastify__toast-body > div:first-child {
    align-self: start;
}
.Toastify__toast-body > div:last-child {
    padding: 0 0px 10px 15px;
}
.Toastify__toast-icon svg {
    width: 20px;
    height: 20px;
}
.Toastify__progress-bar {
    height: 7px;
}
.Toastify__close-button {
    background-color: var(--primary-color);
    opacity: 1;
    width: 20px;
    height: 20px;
    /* position: relative; */
}
.Toastify__close-button svg {
    width: 18px;
    height: 18px;
    margin: 1px 0 0 2px ;
    vertical-align: unset;
}
.Toastify__close-button--light svg {
    fill: var(--toastify-color-light);
}
.Toastify__close-button--dark svg {
    fill: var(--toastify-color-dark);
}
/* END Override the toast styling */

