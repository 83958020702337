.toggle-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
}

.toggle-container label.switch span.slider {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: #fff;
    border: 1px solid var(--primary-cloudy-blue-color);
    border-radius: 100px;
    transition: all .5s;
    vertical-align: middle;
}


.toggle-container label.switch span.label {
    color: black;
    font-family: Roboto Condensed,sans-serif!important;
    letter-spacing: 1.2px;
    font-size: 14px;
    font-weight: 100;
    cursor: pointer;
    text-align: left;
    padding: 0 0 0 10px;
}

.toggle-container label.switch span.slider:after {
    content: "";
    position: absolute;
    background-color: #fff;
    top: 1px;
    left: 1px;
    height: 18px;
    width: 18px;
    border-radius: 400px;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.toggle-container label.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0 10px;
}

.toggle-container label.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-container .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary-color);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.toggle-container  input + .slider {
    background-color: #e3ffff;
}

.toggle-container  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-color: var(--primary-color);
}

/* Rounded sliders */
.toggle-container  .slider.round {
    border-radius: 34px;
}

.toggle-container  .slider.round:before {
    border-radius: 50%;
}

.toggle-container  label.switch {
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    height: 20px;
    width: unset;
}

.toggle-container label.switch span.slider {
    background-color: var(--secondary-white-color);
    width: 45px;
    height: 25px;
}

.toggle-container label.switch span.slider:after {
    background-color: #e2e2e2;
    height: 21px;
    width: 21px
}

.toggle-container label.switch.checked span.slider {
    background-color: var(--primary-color);
}

.toggle-container label.switch.checked span.slider:after {
    background-color: var(--secondary-white-color);
}

.toggle-container label.switch.checked span.slider:after {
    left: calc(100% - 22px);
    transition: all .2s;
}