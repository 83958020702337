.ShowDetails .Alert {
    margin-bottom: 30px;
}

.ShowDetails .Input input,
.ShowDetails .theme-select,
.ShowDetails .Checkbox {
    margin-bottom: 15px;
}

.ShowDetails .informationIcon {
    margin-left: 10px;
    margin-bottom: 10px;
    width: 14px;
    height: auto;
}

.ShowDetails .displayInlineBlock {
    display: inline-block;
}

.ShowDetails .multi-select {
    margin-bottom: 15px;
}

.ShowDetails .doors-open .Input label,
.ShowDetails .multi-select .barcode-label {
    margin-bottom: 5px;
    display: inline-block;
}

.ShowDetails .Checkbox.disabled {
    cursor: not-allowed;
}

.ShowDetails .Checkbox.disabled .checkmark {
    color: var(--secondary-grey-light-color);
    background: var(--disabled);
    background-blend-mode: overlay;
    border: solid 1px var(--primary-cloudy-blue-color);
}

.ShowDetails .Checkbox.disabled .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #b4b4b4;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.ShowDetails > p {
    margin-bottom: 10px;
}

.ShowDetails .img-responsive {
    width: 100%;
}

/* Bardcode info cards */
.card-cell {
    background: var(--table-border, #DEE3ED);
}

/* Reseting Panel */
.ShowDetails .Panel {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
}

.ShowDetails .Edit-Form {
    width: 100%;
}

@media all and (min-width:992px) {
    .ShowDetails .Panel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .ShowDetails .Panel .details {
        margin: 0;
        display: flex;
        align-items: flex-start;
        gap: 20px;
    }
}

@media all and (max-width:991px) {
    .ShowDetails .Panel .details,
    .ShowDetails .Panel .details > div {
        padding: 0;
    }
    .Layout .Content {
        background-color: #fff !important;
    }
    .ShowDetails .Panel {
        padding: 0;
        border: 0;
    }
    .ShowDetails  .doors-open .Input input {
        height: 45px;
    }
    .ShowDetails input.read-only,
    .ShowDetails .Input > input:read-only {
        background-color: rgba(0, 0, 0, 0.151);
        border: 0.5px solid #bdbebe;
    }
    .ShowDetails .informationIcon {
        margin-left: 10px;
        margin-bottom: 10px;
        min-width: 15px;
    }
    .ShowDetails .row.reentry-and-ticket label {
        font-size: 1em !important;
        font-weight: 600;
        height: 50px;
        padding-top: 4px;
        margin-bottom: 0;
        margin-top: 10px;
    }
    .ShowDetails .row.reentry-and-ticket img {
        display: none;
    }
    .ShowDetails .multi-select .dd-wrapper .dd-header {
        padding: 11px 25px 11px 12px;
    }
    .row .col-6.col-md-12:first-child {
        padding-right: 5px;
    }
    .Panel .buttons .col-12:first-child,
    .Panel .buttons .col-12:last-child,
    .Panel .buttons {
        padding: 0 !important;
    }
    .Panel .buttons .col-12:first-child .Button button {
        display: block;
        margin: 6px auto;
        width: 100%;
    }
    .ShowDetails .Panel .buttons .col-12:last-child .Button button {
        display: block;
        margin: 6px auto;
        width: 100%;
    }
    .Panel .buttons .col-12.text-end {
        text-align: left !important;
    }
    .Panel .buttons .col-12:last-child .Button button {
        background-color: #fff !important;
        color: var(--social-pale-red-color);
        padding-left: 0;
    }
}