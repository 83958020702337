.Color > .Input > input {
    width: 42px;
    height: 21px;
    border-radius: 10px;
    background-color: var(--secondary-white-color);
    border: none;
    padding: 0 !important;
}

.Color > .Input > input::-webkit-color-swatch {
    border: none !important;
    padding: 0;
}