.VenueManagement .table-options {
    margin-bottom: 30px;
}

.VenueManagement .table-options .Button button {
    margin-top: 0;
    margin-bottom: 0;
}

.VenueManagement .table-options .Button:last-child button {
    margin-right: 0;
}

.VenueManagement .table-options .Button:last-child button img {
    width: 20px;
}

.VenueManagement .table-options .archived-venues,
.VenueManagement .table-options .archived-venues .toggle-container {
    height: 45px;
}

.VenueManagement .table-options .archived-venues .checkbox {
    margin: 0;
}

.VenueManagement .table-pagination {
    margin-bottom: 40px;
}

.VenueManagement .table-pagination .Button:not(:last-child) {
    margin-right: 10px;
}

.VenueManagement .table-pagination .Button button,
.VenueManagement .table-pagination .Button button.current-page {
    padding: 0 !important;
}

.VenueManagement .table-pagination .Button button.current-page {
    background-color: var(--white-color);
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
}

.right-position {
    text-align: right;
}

.switch-padding-top {
    padding-top: 5px;
}

.VenueManagement .table-options .Search > span > input {
    height: 45px;
}

.VenueManagement table th.shows-col {
    width: 124px;
}

.VenueManagement table th.days-col {
    width: 198px;
    white-space: normal;
}

.VenueManagement table th.devices-col {
    width: 119px;
}

.VenueManagement table th.isolation-col,
.VenueManagement table th.isBYOD-col {
    text-align: center;
    width: 100px;
}

.VenueManagement table th.options-col {
    width: 108px;
}

.VenueManagement .byod,
.VenueManagement .isolated {
    text-align: center;
}

.VenueManagement .isolated svg {
    vertical-align: bottom;
}

.VenueManagement .table-options .col-9 {
    display: flex;
    align-items: center;
}

.VenueManagement .days-cell,
.VenueManagement .shows-cell,
.VenueManagement .devices-cell {
    text-align: right;
}

.VenueManagement .green-bold-text {
    color: var(--social-algae-green-color) !important;
    font-weight: bold;
}
.VenueManagement .yellow-text {
    color: var(--yellow-icon-color);
}

@media all and (max-width:991px) {    

    .VenueManagement .table-options {
        margin-bottom: 15px;
    }
    .VenueManagement .col-md-9.p-0 .col-md-6.text-end.p-0 {
        display: block;
        text-align: right;
    }

    .VenueManagement .col-md-9.p-0 .col-md-6.text-end.p-0 .Button,
    .VenueManagement .col-md-9.p-0 .col-md-6.text-end.p-0 .Button button {
        height: 45px;
        max-height: 45px;
        min-height: 45px;
    }
    .VenueManagement table.List th, 
    .VenueManagement table.List td {
        display: none
    }
    .VenueManagement table.List th:nth-child(1), 
    .VenueManagement table.List td:nth-child(1) {
        display: table-cell;
    }
}

@media all and (max-width:767px) {
    .VenueManagement .venues-filter-menu {
        padding: 0;
    }
    .VenueManagement .archived-venues {
        margin:  5px 0 10px 0;
    }
    
}