.audio-action {
  padding: 20px 30px 30px;
  border: 1px dashed var(--gray-light-color);

  hr {
    opacity: 0.1;
  }
  .action-label {
    display: block;
    white-space: nowrap;
    overflow: visible;
  }
  .select-wrapper {
    padding-top: 15px;
  }
  .toggle-container label.switch {
    margin: 20px 0;
  }
  .Checkbox {
    padding-top: 0;
  }
  .Checkbox > .checkmark {
    top: 0;
  }
  .has-audio {
    gap: 20px;
    padding: 15px 25px;
    font-weight: 500;
    position: relative;
    border-radius: 3px;
  }
  .has-audio .bg {
    z-index: 0;
    opacity: 0.15;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary-color);
  }
  .has-audio * {
    font-size: 16px;
    font-weight: 500;
    line-height: 31px;
    z-index: 1;
  }
  .delete-audio,
  .play-audio {
    cursor: pointer;
  }
  .buttons-group {
    button svg {
      height: 20px;
    }
    button.preview {
      color: white;
      background-color: var(--primary-color);
      border: 1px solid var(--primary-color);
    }
    button.download {
      background-color: transparent;
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
    }
    button:disabled {
      cursor: not-allowed;
      color: var(--secondary-grey-light-color);
      background: repeating-linear-gradient(
          -45deg,
          var(--disabled-background-gradient-start-color),
          var(--disabled-background-gradient-start-color)
        ),
        repeating-linear-gradient(
          -45deg,
          var(--disabled-background-gradient-end-color),
          var(--disabled-background-gradient-end-color),
          2px,
          transparent 4px,
          transparent 2px
        );
      background-blend-mode: overlay;
      border-color: var(--primary-cloudy-blue-color);
    }
    button:disabled svg * {
      fill: var(--secondary-grey-light-color);
    }
  }

  .audio-hidden {
    display: none;
  }
  @media screen and (min-width: 992px) {
    .has-audio {
      padding: 15px 30px 15px 25px;
    }
    .has-audio .bg {
      right: 10px;
    }
  }
}
