.key .tag-filter-labels,
.value .tag-filter-labels {
    display: block;
    font-weight: bold;
}

.operation {
    margin-top: 20px;
}

.helper {
    visibility: hidden;
}

.Tag {
    margin-top: 30px;
    display: flex;
}

.Tag .filters .table-wrapper {
    overflow-x: auto;
}

.Tag .filters .table-wrapper::-webkit-scrollbar {
    height: 0.5em;
}

.Tag .filters .table-wrapper::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--secondary-grey-lightest);
}

.Tag .filters .table-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--primary-color);
}

.Tag .filters table {
    margin-bottom: 30px;
}

.Tag .filters table thead tr th,
.Tag .filters table tbody tr td {
    vertical-align: middle;
}

.Tag .filters table thead tr th:last-child,
.Tag .filters table tbody tr td:last-child {
    text-align: center;
}

.Tag .filters table thead tr th {
    background-color: var(--secondary-grey-lightest);
}

.Tag .filters table thead tr th:first-child {
    padding: 15px 20px;
}

.Tag .filters table tbody tr td:first-child {
    padding-left: 20px;
    white-space: nowrap;
}

.Tag .filters table tbody tr td .Checkbox,
.Tag .filters table tbody tr td:first-child .filters-row {
    display: inline-block;
}

.Tag .filters table tbody tr td .Checkbox span {
    left: 15%;
}

.Tag .filters table tbody tr td:first-child .filters-row {
    padding: 40px 0;
}

.Tag .filters table tbody tr td:first-child .filter {
    height: 45px;
    background-color: var(--white-color);
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    padding: 15px;
    border-radius: 30px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0.4px;
}

.Tag .filters table tbody tr td:first-child .filter img:hover {
    cursor: pointer;
}

.Tag .filters .or,
.Tag .filters table tbody tr td:first-child .and {
    background-color: var(--primary-color);
    padding: 13px 20px;
    color: var(--white-color);
    font-weight: bold;
}

.Tag .filters .or:hover,
.Tag .filters table tbody tr td:first-child .and:hover {
    cursor: pointer;
}

.Tag .filters table tbody tr td:first-child .and-between,
.Tag .filters table tbody tr td:first-child .or-between {
    background-color: var(--white-color);
    padding: 13px 20px;
    color: var(--black-color);
    font-weight: bold;
}

.Tag .filters table tbody tr td:first-child .or-between {
    padding: 13px 20px 13px 0;
}

.Tag .filters .or {
    display: inline-block;
    width: 106px;
    margin-top: 30px;
}

.Tag .filters .apply-filters {
    margin-top: 30px;
}

.Tag .filters .apply-filters .Button,
.Tag .createNewTagBtn .apply-filters .Button button {
    margin-left: 10px;
}

.Tag .filters .apply-filters .Button button {
    margin: 0;
}

.Tag .filters .or img,
.Tag .filters table tbody tr td:first-child .and img {
    padding-bottom: 5px;
    margin-left: 10px;
    height: 20px;
}

.Tag .filters .or img,
.Tag .filters-row .and img {
    position: relative;
    margin: 1px 0 0 10px;
}

.Tag .filters .filters-row {
    display: flex;
    align-items: center;
}

.Tag .filters-row .filter img {
    padding: 0;
}

.Tag .filters table tbody tr td:first-child .and {
    margin-left: 15px;
    position: relative;
}

.modal-header {
    border-bottom: none !important;
}

.modal-content .modal-body {
    padding-bottom: 0;
}

.modal-content .modal-body .key .theme-select,
.modal-content .modal-body .operation .theme-select,
.modal-content .modal-body .value .Input {
    font-weight: 800;
    font-size: 16px;
}

.modal-content .modal-body .key .theme-select,
.modal-content .modal-body .key .tag-filter-labels,
.modal-content .modal-body .value .Input,
.modal-content .modal-body .value .tag-filter-labels {
    display: inline-block;
}

.modal-content .modal-body .value .tag-filter-labels,
.modal-content .modal-body .key .tag-filter-labels {
    margin-right: 25px;
    font-weight: 300;
}

.modal-content .modal-body .key .theme-select {
    width: 60%;
}

.modal-content .modal-body .value .Input {
    width: 76%;
}

.modal-content .modal-body .value .Input input {
    margin-bottom: 0;
}

.modal-content .modal-body .operation {
    margin-top: 0;
    width: 15%;
}

.modal-content .modal-body .operation .theme-select {
    width: 80%;
}

.modal-content .modal-body .key {
    padding-left: 0;
    width: 35%;
}

.modal-content .modal-body .value {
    padding-right: 0;
    padding-left: 10px;
    width: 50%;
}

.modal-footer {
    border-top: none !important;
    padding-top: 0 !important;
}

.tag-dialog {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
@media all and (max-width:991px) {
    .modal-body .operation ,
    .modal-body .key {
        display: flex;
        align-items: center;
    }
    .modal-body .key .theme-select ,
    .modal-body .key.theme-select > select {
        margin-bottom: 0;
    }
    .Tag .filters .table-wrapper {
        margin-bottom: 20px;
    }
}