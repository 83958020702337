.List {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto 40px;
    table-layout: fixed;
    white-space: nowrap;
}

.List th,
.List td {
    border: 1px solid var(--table-border-color);
    padding: 13px;
    font-size: 15px;
    font-stretch: condensed;
    line-height: 1.4;
    letter-spacing: 1.2px;
}

.List td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.List th {
    background-color: var(--primary-cloudy-blue-color) !important;
    font-weight: normal;
}

.List td.grayed,
.List th > span.grayed {
    color: var(--gray-light-color);
}

.List th > span.grayed {
    margin-left: 5px;
}

.List td img {
    width: 16px;
}

.List td img:hover,
.List th img:hover,
.List td svg:hover,
.List th svg:hover {
    cursor: pointer;
}

.List tr.table-footer {
    font-weight: bold;
    font-style: italic;
    font-size: 17px;
    line-height: 1.24;
    background: repeating-linear-gradient(
        -45deg,
        var(--white-color),
        var(--white-color) 2px,
        var(--gray-light-color) 2px,
        var(--gray-light-color) 4px
    );
}

.List td span.options {
    margin: 0;
    position: absolute;
    right: 11px;
    top: 11px;
}

.List td span.options img {
    width: 19px !important;
    margin-left: 10px;
}

.List td span.options img[title="Disabled"],
.List td span.options img.not-allowed {
    cursor: not-allowed;
    pointer-events: none;
}

.List td a {
    display: block;
}

.List td a:hover,
.List td a:active,
.List td a:focus {
    text-decoration: none;
}

.List td.color-black {
    color: var(--black-color) !important;
}

.List td .options img {
    width: 20px !important;
}

.List td.destaged {
    color: var(--gray-light-color) !important;
}


.List td.no-record {
    color: var(--gray-light-color);
}

.List td.pinned img {
    width: 20px;
}

@media all and (max-width:991px) {

    .List td {
        padding: 13px 5px;
    }
    .ShowManagement .List th {
        width: auto !important;
        white-space: normal;
    }
}
