div.still-loading,
div.no-results {
    text-align: center;
    height: 14px;
    font-weight: bold;
    line-height: 16px;
    color: var(--primary-color);
    font-size: 14px;
}

.sorting-arrow.descending-sort polygon:first-child,
.sorting-arrow.ascending-sort polygon:last-child {
    fill: transparent;
}