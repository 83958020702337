.SidebarMenu ul {
    list-style: none;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 1.1px;
}

.SidebarMenu ul li a {
    position: relative;
    display: block;
    color: var(--white-color) !important;
    padding: 12px 30px;
}

.SidebarMenu ul li:not(:last-child) a:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 30px;
    border-bottom: 1px solid var(--gray-lightest-color);
    width: 240px;
}

.SidebarMenu ul li:first-child a:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 30px;
    border-top: 1px solid var(--gray-lightest-color);
    width: 240px;
}

.SidebarMenu ul li a:hover,
.SidebarMenu ul li a:active,
.SidebarMenu ul li a.active {
    text-decoration: none;
}

.SidebarMenu ul li a:hover {
    background-color: var(--primary-dark-color);
}

.SidebarMenu ul li a:active,
.SidebarMenu ul li a.active {
    border-left: 5px solid var(--white-color);
}

.SidebarMenu ul li a img {
    padding-top: 5px;
    display: none;
}

.SidebarMenu ul li a:active img,
.SidebarMenu ul li a.active img {
    display: inline;
}

.SidebarMenu ul li a span.orange {
    background-color: var(--social-orange-yellow);
    margin-left: 5px;
    border-radius: 1.25rem;
}

.SidebarMenu ul li a span.deviceMenuItem {
    margin-left: 5px;
}

.dashboard-sidebar .SidebarMenu ul li a {
    font-size: 26px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}