.Alert{
    padding: 20px;
    line-height: 24px;
    vertical-align: middle;
    display: flex;
    align-items: center;
}

.Alert.Big{
    line-height: 37px;
    display: inherit;
}

.Alert > .title {
    display: flex;
    margin-right: 30px;
}

.Alert > .title > .icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-size: contain;
}

.Alert.Big > .title {
    margin-bottom: 23px;
}

.Alert.Big > .title > .icon {
    margin-right: 20px;
    width: 37px;
    height: 37px;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
}

.Alert.Big > p {
    margin-left: 57px;
    display: block;
    line-height: 1.24;
}

.Alert.error {
    background-color: var(--highlight-pink-light-color);
    color: var(--highlight-red-color);
}

.Alert.error > .title > .icon {
    background-image: url('./images/error.svg');
}

.Alert.info {
    background-color: var(--highlight-pale-color);
    color: var(--yelow-color);
}

.Alert.info > .title > .icon {
    background-image: url('./images/info.svg');
}

.Alert.success {
    background-color: var(--highlight-green-light-color);
    color: var(--highlight-kermit-green-color);
}

.Alert.success > .title > .icon {
    background-image: url('./images/success.svg');
}
