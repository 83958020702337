.Checkpoint {
    display: table;
}
.Checkpoint.row.p-0 {
    display: table;
}
.Checkpoint .entry,
.Checkpoint .exit,
.Checkpoint .event {
    display: table-cell;
    float: none;
}

.Checkpoint .entry,
.Checkpoint .exit {
    vertical-align: middle;
}

.Checkpoint .entry .Button button,
.Checkpoint .exit .Button button {
    margin: 0;
}

.Checkpoint .entry .Button {
    margin-right: 10px;
}

.Checkpoint .exit .Button {
    margin-left: 10px;
}

.Checkpoint .entry .checkpoints img,
.Checkpoint .exit .checkpoints img {
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.Checkpoint .entry .checkpoints img:hover,
.Checkpoint .exit .checkpoints img:hover {
    cursor: pointer;
}

.Checkpoint .entry .checkpoints p > span:hover,
.Checkpoint .exit .checkpoints p > span:hover {
    cursor: cell;
}
.Checkpoint .entry .checkpoints p > span.dot:hover,
.Checkpoint .exit .checkpoints p > span.dot:hover {
    cursor: unset;
}

.Checkpoint .entry .checkpoints p span,
.Checkpoint .exit .checkpoints p span {
    display: inline-block;
    width: 110px;
    white-space: nowrap;
}

.Checkpoint .entry .checkpoints p,
.Checkpoint .exit .checkpoints p {
    display: inline-block;
    position: relative;
    padding: 10px 65px 10px 30px;
    width: 100%;
    margin: 0 0 20px;
    border: 2px solid var(--highlight-kermit-green-color);
    border-radius: 30px;
    font-weight: bold;
    color: var(--primary-color);
    text-align: left;
}

.Checkpoint .entry .checkpoints p:before,
.Checkpoint .exit .checkpoints p:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* Tooltip */
.Checkpoint .exit .checkpoints p .dot,
.Checkpoint .entry .checkpoints p .dot {
    left: 10px;
    width: 9px;
    height: 9px;
    position: absolute;
    border-radius: 50%;
    top: calc(50% - 5px);
}
.Checkpoint .entry .checkpoints p.check-and-check-in .dot,
.Checkpoint .exit .checkpoints p.check-and-check-in .dot {
    background-color: var(--primary-color);
}
.Checkpoint .entry .checkpoints p.check-only .dot,
.Checkpoint .exit .checkpoints p.check-only .dot {
    background-color: var(--highlight-kermit-green-color);
}
.Checkpoint .exit .checkpoints p .dot .custom-tooltip,
.Checkpoint .entry .checkpoints p .dot .custom-tooltip {
    padding: 15px;
    z-index: 1111;
    opacity: 0.8;
    transition: .2s;
    width: max-content;
    visibility: hidden;
    position: absolute;
    color: var(--white-color);
    background-color: var(--secondary-grey-dark-color);
}
.Checkpoint .exit .checkpoints p .dot:hover .custom-tooltip,
.Checkpoint .entry .checkpoints p .dot:hover .custom-tooltip {
    opacity: 1;
    visibility: visible;
}

.Checkpoint .entry .checkpoints.has-many-checkpoints p:after,
.Checkpoint .exit .checkpoints.has-many-checkpoints p:after {
    content: '';
    position: absolute;
    top: 51px;
    right: -70px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 70px;
    height: 65px;
    border-top: 1px solid var(--highlight-kermit-green-color);
    border-right: 2px solid var(--highlight-kermit-green-color);
}

.Checkpoint .exit .checkpoints.has-many-checkpoints p:after {
    left: -71px;
    border-left: 2px solid var(--highlight-kermit-green-color);
    border-right: none;
}

.Checkpoint .entry .checkpoints.has-many-checkpoints p:last-child:after {
    border-right: none;
}

.Checkpoint .exit .checkpoints.has-many-checkpoints p:last-child:after {
    border-left: none;
}

.Checkpoint .entry div:first-child p,
.Checkpoint .exit > p:first-child,
.Checkpoint .event .area-wrapper .content p {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.18;
    letter-spacing: 0.6px;
    margin-bottom: 20px;
}

.Checkpoint .entry {
    padding-bottom: 65px !important;
}

.Checkpoint .entry.has-entry-checkpoint {
    padding-bottom: 0 !important;
}

.Checkpoint .entry div:first-child p {
    padding-right: 30px;
}

.Checkpoint .event {
    position: relative;
}

.Checkpoint .event:after {
    content: '';
    position: absolute;
    top: calc(50% - 14px);
    right: 0;
    border: 1px dashed var(--gray-light-color);
    width: calc((100% - 400px)/2);
}

.Checkpoint .event.has-many-exit-checkpoints:after {
    width: calc((100% - 400px)/2 - 69px);
    right: 69px;
}

.Checkpoint .event:before {
    content: '';
    position: absolute;
    top: calc(50% - 13px);
    left: 0;
    border: 1px dashed var(--gray-light-color);
    width: calc((100% - 400px)/2);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.Checkpoint .event.has-entry-checkpoint:before {
    border: 1px solid var(--highlight-kermit-green-color);
}

.Checkpoint .event.has-many-entry-checkpoints:before {
    width: calc((100% - 400px)/2 - 67px);
    left: 67px;
}

.Checkpoint .event.has-exit-checkpoint:after {
    border: 1px solid var(--highlight-kermit-green-color);
}

.Checkpoint .event .area-wrapper {
    position: relative;
    width: 400px;
    height: 400px;
    margin: 0 auto;
    border: 2px solid var(--highlight-kermit-green-color);
    border-radius: 200px;
}

.Checkpoint .event .area-wrapper .content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.Checkpoint .event .area-wrapper .content p {
    font-size: 26px;
    margin: 0;
}

.Checkpoint .event .area-wrapper .content img {
    width: 80px;
    margin-bottom: 20px;
}

.Checkpoint .exit {
    padding-bottom: 65px !important;
    padding-left: 10px !important;
}

.Checkpoint .exit.has-exit-checkpoint {
    padding: 0 !important;
}

.Checkpoint .exit p:first-child {
    padding-left: 30px;
}

.permission-label {
    margin-right: 20px;
}

.modal-dialog .modal-body .Input {
    position: relative;
}

.modal-dialog .modal-body .Input:after {
    content: '';
    position: absolute;
    top: -2px;
    right: 15px;
    width: 14px;
    height: 14px;
    border-radius:8px;
}

.modal-dialog .modal-body div.error {
    margin: 10px 0;
}

.modal-dialog .modal-body .Input.check-and-check-in:after  {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.modal-dialog .modal-body .Input.check-only:after {
    background-color: var(--highlight-kermit-green-color);
    border: 1px solid var(--highlight-kermit-green-color);
}

@media screen and (max-width:991px) {
    .Checkpoint .exit .checkpoints p:after,
    .Checkpoint .entry .checkpoints p:after {
        content: '';
        position: absolute;
        top: 51px;
        right: -80px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 80px;
        height: 65px;
        border-top: 1px solid var(--highlight-kermit-green-color);
        border-right: 2px solid var(--highlight-kermit-green-color);
    }

    .Panel.remove.edit .Checkpoint {
        display: block;
    }
    .Panel.remove.edit .Checkpoint > div {
        display: block;
        float: left;
        position: relative;
    }
    .Checkpoint .entry .checkpoints p,
    .Checkpoint .exit .checkpoints p {
        display: block;
        width: 40vw;
    }
    .Checkpoint .entry .checkpoints p:last-child:after {
        border-right: 2px solid var(--highlight-kermit-green-color) !important;
    }
    .Checkpoint .exit .checkpoints p:after,
    .Checkpoint .entry .checkpoints p:after {
        width: 6vw !important;
        right: -6vw !important;
        height: 75px !important;
    }
    .Checkpoint .exit .checkpoints p:after {
        left: -6vw !important;
        top: -14px !important;
        border-right: 0 !important;
        border-top: 0 !important;
        border-left: 2px solid var(--highlight-kermit-green-color) !important;
        border-bottom: 2px solid var(--highlight-kermit-green-color) !important;
    }
    .Checkpoint .exit .checkpoints p:first-child:after {
        height: 137px !important;
        width: 6vw !important;
        top: -45px !important;
    }
    .Checkpoint .entry .checkpoints {
        margin-top: 60px;
    }
    .Checkpoint .entry .Button {
        position: absolute;
        top: 50px;
        right: -10px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .Checkpoint .exit .Button {
        position: absolute;
        top: 60px;
        left: 0px;
    }
    .Checkpoint .entry .Button button,
    .Checkpoint .exit .Button button {
        padding-left: 10px;
        padding-right: 10px
    }
    .Checkpoint .exit .checkpoints {
        text-align: right !important;
        margin-top: 66px;
    }
    .Checkpoint .event .area-wrapper {
        width: 350px;
        height: 350px;
        margin: 24px auto 3px auto;
    }
    .Checkpoint .event:after, 
    .Checkpoint .event:before {
        border: 0 !important;
    }
    .Button > button.add > .text:after {
        background: none !important;
        width: 0;
        height: 0;
    }
    .Checkpoint .exit > p:first-child,
    .Checkpoint .entry div:first-child p {
        padding-right: 20px;
        text-align: right;
        margin-top: 15px;
        padding: 0 10px;
    }
    .Checkpoint .exit > p:first-child {
        margin-top: 20px;
        margin-bottom: 5px;
        text-align: left;
    }
    .Panel.remove.edit h3 {
        font-size: 18px;
    }
    
    .Checkpoint .exit .checkpoints p {
        margin: auto ;
        margin-right: 0;
        margin-bottom: 20px;
    }
}