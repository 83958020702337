
.DeviceManagement .devices-buttons-column {
    width: 75px;
}

.DeviceManagement .devices-id-cell {
    color: var(--primary-color) !important;
}

.DeviceManagement table.List tbody tr td:first-child {
    cursor: pointer;
}
.byod-cell,
.DeviceManagement .byod-column {
    width: 100px;
    text-align: center;
}