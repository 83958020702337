.ShowManagement #expanding-card-container.expanding-card-close .expanding-card-body > div > div,
#expanding-card-container.expanding-card-close .expanding-card-body {
    height: 0;
    overflow: hidden;
    transition: all .5s ease;
    height: 0px;
    opacity: 0;
}
.ShowManagement #expanding-card-container.expanding-card-open .expanding-card-body > div > div,
#expanding-card-container.expanding-card-open .expanding-card-body {
    transition: all .5s ease;
    height: 45px;
    margin-bottom: 3rem;
    overflow: visible;
    opacity: 1;
}