#PageNotFound #wrapper {
    width: 325px;
    margin: 300px auto 0;
}

#PageNotFound #wrapper p {
    line-height: 0.8em;
}

#PageNotFound #wrapper p:first-child {
    font-size: 200px;
    color: #35B5FF;
}

#PageNotFound #wrapper p:nth-child(2n) {
    display: block;
    font-size: 46px;
    color: #BBDEFB;
}

#PageNotFound #wrapper p:last-child {
    margin-top: 30px;
}

#PageNotFound #wrapper p:last-child .btn {
    display: block;
    margin: 0 auto;
    padding: 10px;
    border-radius: 6px;
    background-color: #35B5FF;
    font-weight: bold;
    box-shadow: 2px 2px 2px #c2c2c2;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -ms-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;
    color: var(--white-color) !important;
}

#PageNotFound #wrapper p:last-child .btn:hover {
    border: none;
    box-shadow: none;
}

#PageNotFound #wrapper p:last-child .btn img {
    margin-left: 3px;
    width: 18px;
    padding-bottom: 3px;
}

@media screen and (max-width: 730px) {
    #PageNotFound #wrapper {
        width: 250px;
    }

    #PageNotFound #wrapper p:first-child {
        font-size: 150px;
    }

    #PageNotFound #wrapper p:nth-child(2n) {
        font-size: 36px;
    }

    #PageNotFound #wrapper p:last-child .btn {
        width: 60%;
    }
}

@media screen and (max-width: 550px) {
    #PageNotFound {
        width: 80%;
        margin: 0 10%;
    }
}