.pagination {
    margin: 0 !important;
    display: block !important;
}

.pagination.hidden {
    display: none !important;
}

.pagination .Button button,
.pagination .Button button.current-page {
    padding: 0 !important ;
}

.pagination .Button button.current-page {
    background-color: var(--white-color);
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
}

.pagination .Button button.previous,
.pagination .Button button.next,
.pagination .Button button.first,
.pagination .Button button.last {
    width: 80px;
}

.pagination .Button button.last {
    margin-right: 0;
}

.pagination .Button button.next {
    margin-left: 10px;
}