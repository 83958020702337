#users-management table .is-admin,
#users-management table .user-delete {
    width: 110px;
}
#users-management table td:nth-child(1) {
    color: var(--primary-color);
    cursor: pointer;
}
#users-management table td:nth-child(3),
#users-management table th:nth-child(3) {
    text-align: center;
}
#users-management table td:nth-child(4) span {
    top: calc(50% - 13px);
    right: calc(50% - 13px);
}
#users-management .table-options .Button button.add {
    margin-top: 0;
    margin-bottom: 0;
}
.container.not-allowed {
    margin-top: 10vw;
    text-align: center
}
.container.not-allowed h1 {
    opacity: 0;
    animation-name: fadeIn;
    animation-fill-mode: forwards;
    animation-duration: .5s;
    animation-timing-function: ease-in-out;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 567px) {
    #users-management table .is-admin,
    #users-management table .user-delete {
        width: auto;
    }
}