.ShowTickets .show-tickets-options {
    margin-top: 15px;
}

.ShowTickets .show-tickets-options > .search  {
    margin-bottom: 25px;
    margin-top: 25px !important;
    width: calc(100% - 569px);
}
.ShowTickets .show-tickets-options .total-tickets-col {
    width: 326px;
}
.ShowTickets .show-tickets-options > .upload-tickets {
    margin-top: 25px !important;
    width: 243px;
}

.ShowTickets .theme-select {
    width: 200px;
    width: 100%;
}

/* .ShowTickets .upload-tickets {
    padding-right: 0;
} */

.ShowTickets .upload-tickets button,
.upload-tickets .Button button {
    margin-top: 0;
    margin-bottom: 0;
}

.ShowTickets .upload-tickets button img {
    width: 20px;
    margin-left: 10px;
}
.ShowTickets .upload-tickets button img[alt="Refresh"] {
    margin-left: 0;
}

.ShowTickets .show-tickets-table {
    margin-top: 50px;
}

.ShowTickets .total-tickets-col > * {
    display: inline-block;
    margin-bottom: 0;
}

.ShowTickets .total-tickets-col > span {
    margin-right: 10px
}

.ShowTickets .show-tickets-search {
    margin-bottom: 0;
}

fieldset#upload-tickets-type {
    padding: 15px 0 0;
}

fieldset#upload-tickets-type label.Radio {
    display: inline-block;
    margin: 0 0 0 15px;
}

fieldset#upload-tickets-type label.Radio:nth-child(4),
fieldset#upload-tickets-type label.Radio:first-child {
    margin: 0;
}
fieldset#upload-tickets-type label.Radio:nth-child(4) {
    margin-top: 10px;
}
.modal-dialog .qrcode {
    position: absolute;
    top: 25px;
    right: 30px;
}

.modal-dialog .modal-header {
    padding-right: 120px;
}

.modal-dialog .modal-body {
    position: initial;
}

.modal-dialog .modal-body .table,
.modal-dialog .modal-body .table th,
.modal-dialog .modal-body .table td {
    border-color: var(--black-color);
}

.modal-dialog .modal-body .table thead th {
    background-color: var(--primary-cloudy-blue-color);
}

.modal-dialog .modal-body input[type="file"] {
    display: none;
}

.modal-dialog .modal-body .fileName {
    padding: 12px 10px !important;
    border: 1px solid var(--gray-light-color);
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 650px !important;
    }
}

@media (min-width: 1261px) {
    .ShowTickets .show-tickets-search {
        margin-bottom: 0;
        /* max-width: 550px; */
    }
}

@media only screen and (min-width: 991px) and (max-width: 1260px) {
    .ShowTickets .show-tickets-options > .search  {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .ShowTickets .show-tickets-options > .search  {
        width: 100%;
    }
}