.modal-dialog {
    margin-top: 150px !important;
}

.modal-dialog .modal-header {
    padding-left: 30px;
    padding-bottom: 0;
}

.modal-dialog .modal-content {
    border-radius: 0 !important;
}

.modal-footer {
    margin-left: 15px;
    text-align: left !important;
}

.create-button, .cancel-button {
    padding-left: 35px !important;
    padding-right: 35px !important;
}

.additional-title {
    font-weight: normal;
    color: var(--secondary-grey-dark-color);
}

.modal-body {
    padding: 0 30px 0 30px !important;
}
.modal-body p {
    margin-bottom: 10px;
    font-size: 18px;
}

.modal-dialog .modal-header h1 {
    white-space: pre-wrap;
    font-size: 36px;
}

.fw-bold {
    font-weight: bold;
}
.modal .modal-dialog {
    transform: translate(0, -14%) !important;
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 650px!important;
        max-width: 650px!important;
    }
}
@media all and (max-width: 991px) {
    .modal .modal-dialog {
        transform: translate(0) !important;
    }
    .modal-dialog .modal-header {
        padding: 15px 15px 15px 15px;
    }
    .modal-body {
        padding: 0 15px 0 15px !important;
    }
    .modal-footer {
        margin-left: 0;
    }
    .modal-dialog {
        margin-top: 25px !important;
    }
}