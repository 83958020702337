.Logs .results-title {
    line-height: 48px;
}

.Logs .download > * {
    font-weight: bold;
    display: inline-block;
    padding: .9rem 1.5rem;
    text-transform: uppercase;
    color: var(--white-color) !important;
    background-color: var(--primary-color);
}

.Logs .download a:hover {
    background-color: var(--primary-dark-color);
}

.Logs .download .disabled {
    border: 0;
    cursor: not-allowed;
    background-blend-mode: overlay;
    color: var(--gray-light-color);
    background: repeating-linear-gradient( -45deg, var(--disabled-background-gradient-start-color), var(--disabled-background-gradient-start-color)), repeating-linear-gradient(-45deg, var(--disabled-background-gradient-end-color), var(--disabled-background-gradient-end-color), 2px, transparent 4px, transparent 2px) !important;
}

.Logs .ticket-info .Button,
.Logs .ticket-info .Button .medium,
.Logs .ticket-info .Button .medium .text {
    height: 24px;
    width: 34px;
    padding: 0 !important;
}

.Logs .logs-panel .list-header {
    border: 1px solid var(--table-border-color);
    font-size: 15px;
    font-stretch: condensed;
    line-height: 1.4;
    letter-spacing: 1.2px;background-color: var(--primary-cloudy-blue-color);
    font-weight: bold;
}
.Logs .logs-panel .list-header > div {
    padding: 15px;
}
.Logs .logs-panel .list-header > div:not(:last-child) {
    border-right: 1px solid var(--table-border-color);
}
.Logs .total-tickets.hasResults {
    font-size: 20px;
    padding: 0.1rem 8px;
    line-height: normal;
    vertical-align: baseline;
    color: var(--white-color);
    background-color: var(--toastify-icon-color-success);
}

@media screen and (max-width:1200px) {
    .Logs .logs-panel {
        overflow-x: auto;
        max-width: calc(100vw - 400px);
    }
}

@media screen and (max-width:991px) {
    .Logs .logs-panel {
        border-right: 1px solid var(--secondary-grey-lightest);
    }
    .Logs .logs-panel {
        max-width: calc(100vw - 20px);
    }
}