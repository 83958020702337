#footer {
    min-height: 69px;
    font-family: "Be Vietnam Pro";
}

#footer .sub {
    color: rgba(255, 255, 255, 0.70);
    font-family: inherit;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.4px;
}

#footer .address {
    color: #fff;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.15px;
}

#footer #ch-incident-status button {
    margin-right: 15px;
}