.DashboardMenu {
    background-color: var(--primary-color);
    height: 60px;
}

.DashboardMenu .menu-item {
    position: relative;
    height: 60px;
    width: 25%;
}

.DashboardMenu .menu-item:hover {
    cursor: pointer;
}

.DashboardMenu .menu-item a {
    position: absolute;
    display: block;
    height: 60px;
    width: 100%;
    left: 0;
    bottom: 0;
    color: var(--white-color);
    line-height: 14px;
    padding: 23px 0;
    color: var(--white-color) !important;
}
.DashboardMenu .tabs-container {
    max-width: 985px;
    margin: auto;
}
.DashboardMenu .menu-item a:hover,
.DashboardMenu .menu-item a:focus {
    text-decoration: none;
}

.DashboardMenu .menu-item.selected-item a {
    position: absolute;
    padding: 28px 20px 28px 0;
    top: -10px;
    height: 75px;
    background: url('./images/tabsBackground.png') round;
    z-index: 1;
    color: var(--black-color) !important;
}

.DashboardMenu .menu-item.selected-item a {
    color: var(--black-color);
}