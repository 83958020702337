.Input > input {
    border: solid 1px var(--gray-light-color);
    box-shadow: 1px 1.8px 2px 0 var(--shadow-color);
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.Input > input::placeholder {
    padding-left: 15px;
    font-size: 17px;
    color: var(--secondary-grey-light-color);
}

.Input > input:focus {
    outline:none;
    border:1px solid var(--black-color);
    -webkit-box-shadow: 0px 0px 5px var(--shadow-color);
    box-shadow: 3px 3px 5px var(--shadow-color);
}

.Input > label.required:after {
    content: ' *';
}

.Input > label {
    margin-right: 25px;
}

.Input > label.checkbox {
    display: inline-block;
    margin-left: 5px;
}

.Input > input.error {
    background: var(--highlight-pink-light-color) url(../../images/error.svg) no-repeat scroll 10px 10px;
    background-position: 97% 50%;
    background-size: 20px;
    border: solid 1px var(--red-color);
    color: var(--red-color);
}

.Input > label.error {
    color: var(--red-color);
}

.Input > input.info {
    background: var(--highlight-pale-color) url(../../images/info.svg) no-repeat scroll 10px 10px;
    background-position: 97% 50%;
    background-size: 20px;
    border: solid 1px var(--yelow-color);
    color: var(--yelow-color);
}

.Input > label.info {
    color: var(--yelow-color);
}

.Input > input.success {
    background: var(--highlight-green-light-color) url(../../images/success.svg) no-repeat scroll 10px 10px;
    background-position: 97% 50%;
    background-size: 20px;
    border: solid 1px var(--highlight-kermit-green-color);
    color: var(--highlight-kermit-green-color);
}

.Input > label.success {
    color: var(--highlight-kermit-green-color);
}

.Input > input:read-only {
    border: none;
    background-color: #f2f2f2;
}

.Input > input:disabled {
    cursor: not-allowed;
    color: var(--secondary-grey-light-color);
    background:
            repeating-linear-gradient( -45deg, var(--disabled-background-gradient-start-color), var(--disabled-background-gradient-start-color)),
            repeating-linear-gradient(-45deg, var(--disabled-background-gradient-end-color), var(--disabled-background-gradient-end-color), 2px, transparent 4px, transparent 2px) !important;
    background-blend-mode: overlay;
    border: solid 1px var(--primary-cloudy-blue-color) !important;
}