
.dd-list {
    background-color: var(--white-color);
    box-shadow: 0 5px 25px rgba(160, 160, 160, 0.50);
    max-height: 220px;
    overflow-y: auto;
    outline: 0.1px solid rgba(	140, 180, 255, 1);
    padding: 5px 0;
    position: absolute;
    width: 100%;
    z-index: 10;
}

.dd-list-item {
    position: relative;
    cursor: pointer;
    margin: 0;
    height: 35px;
}

.dd-list-item:hover {
    background-color: var(--primary-dark-color);
}

.dd-list-item:hover > .dd-list-item__title {
    color: var(--white-color);
}

.dd-list-item .checkmark {
    position: absolute;
    top: 6px;
    left: 12px;
    width: 23px;
    height: 23px;
    background-color: var(--secondary-white-color);
    border: solid 1px var(--primary-cloudy-blue-color);
    z-index: 1;
}

.dd-list-item .checkmark.active {
    background-color: var(--primary-color);
}

.checkmark-icon {
    position: absolute;
    width: 6px;
    height: 12px;
    left: 20px;
    top: 10px;
    border: solid var(--secondary-white-color);
    border-width: 0 3px 3px 0;
    z-index: 5;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.dd-list-item__title {
    position: absolute;
    top: 10.5px;
    left: 50px;
}