.DialogError {
    width: 400px !important;
}

.DialogError .modal-header {
    position: relative;
    height: 100px;
    background-color: var(--error-color);
}

.DialogError .modal-header img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 60px;
}

.DialogError .modal-body h3 {
    margin: 20px 0;
}

.DialogError .modal-footer {
    text-align: center !important;
    justify-content: center !important;
    margin: 25px 0 0;
    padding-bottom: 25px;
}

.DialogError .modal-footer .Button button {
    margin: 0;
    background-color: var(--error-color);
}