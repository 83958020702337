.theme-select {
  position: relative;
  margin-bottom: 15px;
}

.Logs .theme-select {
  margin-bottom: 0;
}

.theme-select img {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.theme-select select.hasno-svg {
  width: 100%;
  background-color: var(--white-color) !important;
  padding: 10px 25px 12px 12px;
  font-size: 17px;
  cursor: pointer;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: 0.4px;
  border: 1px solid var(--primary-cloudy-blue-color);
  box-shadow: 1px 1.8px 2px 0 var(--shadow-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 45px;
  background-image: url(../../../../../assets/img/arrows.svg);
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}

.theme-select select.hasno-svg:focus {
  outline: none;
}

.theme-select select.hasno-svg:disabled {
  cursor: not-allowed;
  color: var(--secondary-grey-light-color);
  background: repeating-linear-gradient(
      -45deg,
      var(--disabled-background-gradient-start-color),
      var(--disabled-background-gradient-start-color)
    ),
    repeating-linear-gradient(
      -45deg,
      var(--disabled-background-gradient-end-color),
      var(--disabled-background-gradient-end-color),
      2px,
      transparent 4px,
      transparent 2px
    ) !important;
  background-blend-mode: overlay;
  background-image: url(../../../../../assets/img/arrows_disabled.svg);
  border: solid 1px var(--primary-cloudy-blue-color) !important;
}
