.Info {
    background-color: lightgrey;
    padding: 40px 150px;
}

.Info .info-list {
    margin-top: 20px;
}

.Info .info-list ul {
    margin-left: 40px;
}

@media all and (max-width:991px) {
    .Info {
        padding: 3rem 2rem;
    }
}