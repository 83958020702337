.ActionFilters {
  .Panel {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  }

  .Panel > .PanelHeader:hover {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  .Panel > .PanelHeader:active {
    pointer-events: auto !important;
    cursor: grabbing !important;
    cursor: -moz-grabbing !important;
    cursor: -webkit-grabbing !important;
  }

  .Panel.action-controls .theme-select {
    margin-bottom: 0;
  }

  .Panel.action-controls > .row > div:nth-child(2) {
    padding-top: 30px;
  }

  @media all and (max-width: 991px) {
    .Panel {
      padding: 10px;
    }
    .devices .Panel {
      padding: 1rem;
    }
    .Panel .PanelHeader.fill {
      margin: -10px -10px 0px -10px;
    }
  }

  .Panel > .row,
  .Rule {
    margin-top: 0;
  }
}
