.device, .devicePlain {
    display: inline-block;
}

.device > .info {
    cursor: move;
}

.devicePlain > .info {
    height: 75px;
    min-height: 75px;
    max-height: 75px;
}

.devicePlain > .info > p {
    margin-top: 16px;
    margin-bottom: 0;
    color: var(--primary-color);
    font-weight: bold;
    font-size: 20px;
}

.device > .info, .devicePlain > .info {
    padding-top: 5px;
    width: 50px;
    position: relative;
    text-align: center;
    border-radius: 5px;
    border: 2px solid var(--black-color);
}

.device > .info > .overlay {
    cursor: not-allowed;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    opacity: 0.8;
}

.device > .info > .overlay.away {
    cursor: move;
}

.device > .info > .overlay > a > div {
    width: 80px;
    height: 54px;
    z-index: 3;
}

.device > .info > .overlay.destaging {
    border: 2px solid var(--highlight-red-color);
    background-color: var(--highlight-pink-light-color);
    color: var(--highlight-red-color)
}

.device > .info > .overlay.staging {
    border: 2px solid var(--yelow-color);
    background-color: var(--highlight-pale-color);
    color: var(--yelow-color);
    z-index: 998;
}

.device > .info > .overlay.waiting {
    border: 2px solid var(--primary-dark-color);
    background-color: var(--primary-cloudy-blue-color);
    color: var(--yelow-color);
    z-index: 3;
}

.device-line {
    margin: 5px 20px 8px 20px;
    padding: 3px;
    border-bottom: 2.2px solid black;
}

.deviceName {
    margin-top:10px;
    margin-bottom:20px;
    color: var(--primary-color);
    font-size: 11px;
    text-align: center;
}

.delete-icon {
    cursor: pointer;
    position: absolute;
    top: -12%;
    right: -22%;
    background-color: white;
    border-radius: 15px;
    width: 20px;
    height: 20px;
    z-index: 10;
}

.delete-icon > div > img {
    width: 100%;
}

.info.away {
    border: 2px solid var(--gray-light-color);
}

.device {
    vertical-align: top;
    margin-right: 15px;
    text-align: center;
}
.device .info {
    margin: auto;
}

@media all and (max-width: 991px) {
    .Panel > .device {
        margin-right: 15px !important;
    }
    .deviceName {
        margin-top: 5px;
        max-width: 50px;
        margin-bottom:20px;
    }
    .device .info .device-line {
        display: none;
    }
    .Panel .PanelHeader.fill h3 {
        font-size: 16px;
    }
    .Panel span.Button > button.small {
        margin-top: 5px;
        margin-bottom: 0;
    }
}