.Shows .no-list-results {
    position: relative;
    z-index: 0;
}

.Shows .no-list-results:hover {
    cursor: not-allowed;
}

.Shows .no-list-results:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--gray-lightest-color);
    z-index: 1;
}

.Shows .time-frame > div {
    margin-bottom: 15px;
}

.Shows .time-frame > div:first-child {
    margin: 15px 0 25px 35px;
}

.Shows .time-frame > div:not(:first-child) > div:first-child,
.Shows .time-frame > div:not(:first-child) > div:last-child {
    padding: 15px 0 0 35px !important;
}

.Shows .List {
    margin-bottom: 20px !important;
}

.Shows .venue-shows.List td:nth-child(9) {
    text-align: right;
}
.Shows .venue-shows-table-container {
    overflow-x: auto;
    margin-top: 20px;
}

.Shows .venue-shows-table-container th {
    text-transform: capitalize;
}

.Shows .venue-shows-table-container th:nth-child(2),
.Shows .venue-shows-table-container th:nth-child(3) {
    width: 150px !important;
}
.Shows .venue-shows-table-container th:nth-child(1) {
    width: 148px !important;
}
.Shows .venue-shows-table-container th:nth-child(4),
.Shows .venue-shows-table-container th:nth-child(5),
.Shows .venue-shows-table-container th:nth-child(7) {
    width: 165px !important;
}
.Shows .venue-shows-table-container th:nth-child(6),
.Shows .venue-shows-table-container th:nth-child(8),
.Shows .venue-shows-table-container th:nth-child(9) {
    width: 145px !important;
}
.Shows .venue-shows-table-container th:nth-child(10) {
    width: 77px !important;
}
.Shows .venue-shows-table-container td:nth-child(8),
.Shows .venue-shows-table-container td:nth-child(9) {
    text-align: right !important;
}
.Shows .Radio {
    margin-bottom: 46px;
}
.Shows .results-inputs-container {
    display: flex;
}
.Shows .show-results,
.Shows .RadioGroup.inline {
    display: inline-block;
}
.Shows .RadioGroup.inline {
    margin-left: 2vw;
}
.Shows .RadioGroup.inline > label {
    margin: 0 0 15px 0 ;
    display: block;
}
.Shows .theme-select select {
    width: 70px;
}
.Shows .theme-select img {
    right: unset;
    left: 50px;
}
.Shows .reload,
.Shows .theme-select {
    display: inline-block;
}
.Shows .theme-select {
    margin: 0 10px 0 0;
}
.Shows .only-desktop {
    display: block
}
.Shows .only-mobile{
    display: none;
}

.Shows .Tag span.and,
.Shows .Tag span.or {
    position: relative !important;
    padding-right: 40px !important;
}

.Shows .Tag span.or img,
.Shows .Tag span.and img {
    top: calc(50% - 8px) !important;
    position: absolute !important;
}

@media all and (max-width:1199px) {

    .Shows .Panel .time-frame .col-5.col-md-9.p-0 {
        padding: 0 !important;
        line-height: 45px;
    }
    .Shows .results-inputs-container {
        display: block;
    }
    .Shows .show-results,
    .Shows .RadioGroup.inline {
        display: block;
        margin: 10px 0 0 0;
    }
    .Tag .filters .or img,
    .Shows .only-desktop {
        display: none;
    }
    .Shows .only-mobile{
        display: inline-block;
        height: 45px;
        line-height: 45px;
        width: calc(100% - 125px);
        text-align:left;
    }
    .Shows .perpages-container {
        display: block;
        text-align: left
    }
    .Shows .perpages-container .theme-select {
        float: left;
    }
    .Shows .perpages-container .reload {
        float: right;
    }
    .Shows .col-12.col-lg-7.text-end {
        margin-top: 15px;
    }
    .Tag .filters .apply-filters .Button button {
        margin: auto;
        display: block;
        margin-top: 10px;
        width: 170px;
    }
    .Tag .filters .or {
        width: 67px;
        margin: 0 0 0 0;
        padding: 10px;
        width: 55px;
        text-align: center;
    }
    .Shows .time-frame > div:first-child {
        margin: 15px 0 25px 0;
        padding: 0 15px 0 15px !important;
    }
    .Shows .time-frame > div:not(:first-child) > div:first-child,
    .Shows .time-frame > div:not(:first-child) > div:last-child {
        padding: 15px 0 0 20px !important;
    }
    .Shows .time-frame .col-12 > .col-3.col-md-4 {
        padding-left: 0;
    }
}

@media all and (max-width:576px) {
    
    .Shows .Panel.first .Radio {
        margin-bottom: 10px;
        height: unset;
    }
    .Shows h3 {
        font-size: 16px;
    }
    .Tag .filters table tbody tr td:first-child .filter {
        text-align: center;
        background-color: var(--white-color);
        color: var(--primary-color);
        border: 2px solid var(--primary-color);
        padding: 10px;
        border-radius: 20px;
        font-size: 14px;
        max-width: 240px;
        width: 240px;
        height: unset;
        display: block;
        white-space: normal;
        margin: 15px 5px;
    }
    .Tag .filters table tbody tr td:first-child .filters-row {
        padding: 20px 5px;
    }.Tag .filters table tbody tr td:first-child {
        padding-left: 0;
    }
    .Tag .filters table tbody tr td:first-child .filter img[title="Remove"] {
        float: right;
    }
    .Tag .filters table tbody tr td:first-child .filter img[title="edit"] {
        float: left;
    }
    .Tag .filters table tbody tr td:first-child .filter img[title="Remove"],
    .Tag .filters table tbody tr td:first-child .filter img[title="edit"] {
        width: 15px;
        height: 15px;
        margin: 0 3px;
    }
    .Tag .filters table tbody tr td:first-child .and.text-uppercase {
        margin: 0 0 0 5px;
        padding: 10px;
        width: 55px;
    }
    .Tag .filters table tbody tr td:first-child .and.text-uppercase {
        padding-right: 15px !important;
    }
    .Tag .filters table tbody tr td:first-child .and.text-uppercase img {
        display: none
    }
    .Tag .filters table tbody tr td:first-child .and-between {
        margin: 15px 0;
    }
}