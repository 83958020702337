.ToggleSwitch {
    background-color: var(--primary-color);;
    display: inline-block;
    width: auto;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    padding: 4px 0px 10px 0px;
    border-radius: 20px;
}

.ToggleSwitch.disabled {
    cursor: not-allowed;
    background: var(--disabled);
    background-blend-mode: overlay;
    border: solid 1px var(--primary-cloudy-blue-color);
}

.ToggleSwitch > label {
    display: inline-block;
    padding-left: 15px;
    padding-right: 0;
    margin-right: 15px;
    margin-bottom: 0;
    color: var(--secondary-white-color);
    text-transform: uppercase;
}

.ToggleSwitch > label > .checkmark {
    display: none;
}

.ToggleSwitch > label .label-text {
    padding: 2px 25px;
}

.ToggleSwitch.disabled > label .label-text {
    cursor: not-allowed;
}

.ToggleSwitch > label .label-text.checked {
    background-color: var(--secondary-white-color);
    border-radius: 14px;
    padding: 2px 25px;
    margin-top: -5px;
    display: inline-block;
    color: var(--primary-color);
}

.ToggleSwitch.disabled > label .label-text.checked {
    cursor: not-allowed;
    color: var(--secondary-grey-light-color);
}