.battery-empty, .battery-quarter {
    fill: var(--red-color);
}

.battery-half {
    fill: var(--social-orange-yellow);
}

.battery-full {
    fill: var(--social-algae-green-color);
}