div.date-picker {
    position: relative;
}

div.date-picker.has-icon input {
    background-size: 25px;
    background-position: calc(100% - 10px) center;
    background-image: url(../../assets/img/calendar.svg);
}

.date-picker > svg#date-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 8px;
    right: 8px;
}

div.date-picker input {
    padding-left: 16px;
    font-weight: bold;
    font-size: 17px;
    border: solid 1px var(--primary-cloudy-blue-color);
    height: 45px;
    width: 100%;
    background: url(images/ico-date.svg) no-repeat scroll 10px 10px;
    background-position: 97% 50%;
    background-size: 22px;
    margin-bottom: 10px;
    -webkit-box-shadow: 1px 1.8px 2px 0 var(--shadow-color);
    box-shadow: 1px 1.8px 2px 0 var(--shadow-color);
    outline: none;
}

.modal-dialog .react-datepicker {
    width: 422px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    display: block;
}

div.date-picker input::placeholder {
    color: #c6c7c8;
}

div.date-picker input.dark-placeholder::placeholder {
    color: #333;
}

div.date-picker input:focus {
    border-color: var(--black-color);
}

.dd-header-title {
    cursor: pointer;
}

div.date-picker input:disabled {
    cursor: not-allowed;
    color: var(--secondary-grey-light-color);
    background:
            repeating-linear-gradient( -45deg, var(--disabled-background-gradient-start-color), var(--disabled-background-gradient-start-color)),
            repeating-linear-gradient(-45deg, var(--disabled-background-gradient-end-color), var(--disabled-background-gradient-end-color), 2px, transparent 4px, transparent 2px) !important;
    background-blend-mode: overlay;
    border: solid 1px var(--primary-cloudy-blue-color) !important;
}

.mini-date-input input {
    margin-left: 22px;
}

.mini-date-input div,
.mini-date-input .react-datepicker-wrapper,
.mini-date-input .react-datepicker__input-container {
    display: inline-block;
}

div.mini-date-input input {
    width: 145px;
    background-size: 22px;
    background-position: 92% 48%;
}

.react-datepicker-time__header {
    font-size: 12px;
}
.react-datepicker__header {
    height: 70px;
}
.react-datepicker__header--time {
    line-height: 53px;
}

.react-datepicker__time-box ul li {
    font-size: 12px;
}

.react-datepicker-wrapper input.error {
    background: var(--highlight-pink-light-color) url(../../themes/default/Form/images/error.svg) no-repeat scroll 10px 10px;
    background-position: 97% 50%;
    background-size: 20px;
    border: solid 1px var(--highlight-red-color);
    color: var(--highlight-red-color);
}

/* Start Override the datepicker original style */
.react-datepicker__time-list,
.react-datepicker__time-container {
    height: 230px !important;
}
.react-datepicker__month-container,
.react-datepicker__time-container {
    height: 300px !important;
}
.react-datepicker__month-container {
    width: 335px;
}
.react-datepicker__current-month{
    font-size: 16px;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 40px;
    height: 34px;
    font-size: 16px;
}
.react-datepicker__week > div, .react-datepicker__day-names > div {
    line-height: 2em;
}
/* End Override the datepicker original style */

@media all and (max-width: 991px) {
    /* Months view */
    .date-picker .react-datepicker-popper .react-datepicker__month-container {
        width: 250px;
    }
    .date-picker .react-datepicker-popper .react-datepicker__month-container .react-datepicker__day-names .react-datepicker__day-name,
    .date-picker .react-datepicker-popper .react-datepicker__month-container .react-datepicker__week > div {
        width: 30px;
        height: 24px;
        font-size: 12px;
    }
    .react-datepicker__header {
        height: 58px;
    }
    .react-datepicker,
    .react-datepicker__time-container .react-datepicker__time,
    .react-datepicker__time-container,
    .react-datepicker__month-container {
        height: 230px;
        overflow: hidden;
    }
    .ShowDetails div.date-picker input {
        margin-bottom: 15px;
    }
    .modal-dialog .react-datepicker {
        width: 337px;
    }
}

@media all and (max-width: 491px) {
    div.date-picker.has-icon input {
        background-image: none;
    }
}