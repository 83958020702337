#edit-user {
    max-width: 1500px;
    margin: auto;
}
#edit-user input {
    height: 45px;
    font-size: 17px;
    font-size: 1rem;
    letter-spacing: .5px;
}
#edit-user input:disabled {
    background: none !important;
}
#edit-user .theme-select {
    width: 150px;
    margin: 20px 0px;
    display: inline-block;
}
#edit-user .personal-information .toggle-container {
    margin: 20px 50px;
    display: inline-block;
}
#edit-user .theme-select select {
    cursor: pointer;
}
#edit-user .personal-information input:disabled {
    color: var(--black-color);
    background-color: #f2f2f2 !important;
}

#edit-user .security .switch {
    margin-left: 0;
}

@media screen and (min-width:991px) {
    #edit-user .edit-user-title {
        display: none;
    }
}
@media screen and (max-width: 576px) {
    #edit-user .toggle-container {
        margin: 20px 0;
        display: block;
    } 
    #edit-user .personal-information .toggle-container {
        margin: 20px 50px 20px 0;
        display: inline-block;
    }
    #edit-user .personal-information .toggle-container .switch {
        margin-left: 0;
    }
}