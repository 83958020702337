/* The container */
.Checkbox {
    margin-left: 0;
    display: block;
    height: 22px;
    position: relative;
    padding-left: 35px;
    padding-top: 5px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    font-weight: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Checkbox.disabled {
    cursor: default;
    color: var(--secondary-grey-light-color);
}

.Checkbox.disabled .checkmark {
    color: var(--secondary-grey-light-color);
    background: var(--disabled);
    background-blend-mode: overlay;
    border: solid 1px var(--primary-cloudy-blue-color);
}

/* Hide the browser's default checkbox */
.Checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.Checkbox > .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: var(--secondary-white-color);
    border: solid 1px var(--primary-cloudy-blue-color);
}

/* On mouse-over, add a grey background color */
.Checkbox:hover input ~ .checkmark {
    background-color: var(--secondary-white-color);
    border: solid 1px var(--secondary-grey-dark-color);
}

.Checkbox.disabled:hover input ~ .checkmark {
    border: solid 1px var(--primary-cloudy-blue-color);
    background: var(--disabled);
}

/* When the checkbox is checked, add a blue background */
.Checkbox input:checked ~ .checkmark {
    background-color: var(--primary-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.Checkbox > .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.Checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.Checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid var(--secondary-white-color);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
