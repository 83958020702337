.Textarea > textarea {
    border: solid 1px var(--primary-cloudy-blue-color);
    box-shadow: 1px 1.7px 2px 0 var(--shadow-color);
    height: 80px;
    width: 400px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 16px;
}

.Textarea > textarea::placeholder {
    padding-left: 15px;
    padding-top: 10px;
    font-size: 17px;
    color: var(--secondary-grey-light-color);
}

.Textarea > textarea:focus {
    outline:none;
    border:1px solid var(--primary-cloudy-blue-color);
    -webkit-box-shadow: 0px 0px 5px var(--shadow-color);
    box-shadow: 3px 3px 5px var(--shadow-color);
}

.Textarea > textarea.error {
    background: var(--highlight-pink-light-color) url(../../images/error.svg) no-repeat scroll 10px 10px;
    background-position: 97% 50%;
    background-size: 20px;
    border: solid 1px var(--highlight-red-color);
    color: var(--highlight-red-color);
}

.Textarea > textarea.info {
    background: var(--highlight-pale-color) url(../../images/info.svg) no-repeat scroll 10px 10px;
    background-position: 97% 50%;
    background-size: 20px;
    border: solid 1px var(--yelow-color);
    color: var(--yelow-color);
}

.Textarea > textarea.success {
    background: var(--highlight-green-light-color) url(../../images/success.svg) no-repeat scroll 10px 10px;
    background-position: 97% 50%;
    background-size: 20px;
    border: solid 1px var(--highlight-kermit-green-color);
    color: var(--highlight-kermit-green-color);
}

.Textarea > textarea:disabled {
    color: var(--secondary-grey-light-color);
    background:
            repeating-linear-gradient( -45deg, var(--disabled-background-gradient-start-color), var(--disabled-background-gradient-start-color)),
            repeating-linear-gradient(-45deg, var(--disabled-background-gradient-end-color), var(--disabled-background-gradient-end-color), 2px, transparent 4px, transparent 2px) !important;
    background-blend-mode: overlay;
    border: inherit !important;
}