.TopNavigationMenu nav {
    padding: 26px 0 26px 20px !important;
}

.TopNavigationMenu nav ul {
    margin-bottom: 0;
    text-align: left;
}

.TopNavigationMenu nav ul > li {
    position: relative;
    display: inline-block;
    margin-left: 20px;
}

.TopNavigationMenu nav ul > li.no-hamburger {
    margin-left: 20px;
}

.TopNavigationMenu nav ul > li.drop,
.TopNavigationMenu nav ul > li.drop a {
    position: relative;
}

.TopNavigationMenu nav ul > li.drop > ul.drop-menu {
    display: none;
    position: absolute;
    top: calc(100% + 15px);
    right: calc(100%/2 - 160px);
    z-index: 1000;
    min-width: 320px;
    background-color: var(--primary-color);
}

.TopNavigationMenu nav ul > li.drop > ul.drop-menu:before {
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translate(-50%);
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid var(--primary-color);
}

.TopNavigationMenu nav ul > li.drop > ul.drop-menu > li {
    position: relative;
    display: block;
    margin: 0;
    text-align: center;
    font-weight: bold;
}

.TopNavigationMenu nav ul > li.drop > ul.drop-menu > li:not(:last-child):after {
    content: '';
    position: absolute;
    top: 100%;
    left: 10%;
    border-top: 1px solid var(--gray-lightest-color);
    width: 80%;
}

.TopNavigationMenu nav ul > li.drop > ul.drop-menu > li:hover {
    background-color: var(--primary-dark-color);
}

.TopNavigationMenu nav ul > li.drop > ul.drop-menu > li > a {
    display: block;
    height: 100%;
    padding: 20px 0;
}

.TopNavigationMenu nav ul > li.drop > ul.drop-menu > li > a:hover {
    color: var(--white-color);
    text-decoration: none;
}

.TopNavigationMenu nav ul > li.drop:hover > ul.drop-menu {
    display: block;
}

.TopNavigationMenu nav ul > li a {
    color: var(--white-color) !important;
    padding-left: 27px;
}

.TopNavigationMenu nav ul > li > a:hover {
    color: var(--primary-color);
    text-decoration: none;
}

.TopNavigationMenu nav ul > li > a:active,
.TopNavigationMenu nav ul > li > a.active {
    padding-top: 5px;
    text-decoration: none;
}

.TopNavigationMenu nav ul > li img.nav-icon {
    height: 20px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: 1px;
}