.Scene > .title {
    font-size: 36px;
}

.Scene .title span {
    font-size: 12px;
    font-weight: bold;
    color: var(--primary-color);
    letter-spacing: 1.2px;
}

.Scene .title span img {
    width: 16px;
    margin-right: 10px;
}

.Scene.has-children h1 {
    margin-bottom: 0px !important;
    font-size: 36px !important;
}

.Scene.has-children p {
    margin: 11px 0 30px 0 !important;
    font-style: italic;
    font-size: 19px;
    color: grey;
    font-weight: 300;
    text-transform: uppercase;
}
@media all and (max-width: 991px) {
    .Scene.has-children h1 {
        font-size: 26px !important;
    }
    .Scene.has-children p {
        font-size: 16px;
        font-style: normal;
    }
}