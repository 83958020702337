.ShowManagement .only-created-by-admin,
.ShowManagement .begin-after,
.ShowManagement .end-before {
    padding: 8px 50px !important;
}

.ShowManagement .begin-after .Checkbox,
.ShowManagement .begin-after .Input,
.ShowManagement .end-before .Checkbox,
.ShowManagement .end-before .Input {
    display: inline-block;
}

.ShowManagement .begin-after .Input,
.ShowManagement .end-before .Input {
    margin: 0 0 0 20px;
}

.ShowManagement .end-before {
    margin-bottom: 30px;
    padding-right: 0 !important;
}

.ShowManagement .end-before .theme-select {
    width: 90%;
}

.ShowManagement .end-before .Button .medium {
    margin: 0;
}

.ShowManagement .end-before .Button .medium.add {
    margin-left: 40px;
}

.ShowManagement .end-before .Button .medium img {
    width: 20px;
}

.ShowManagement button svg {
    width: 25px;
    height: 25px;
}

.ShowManagement label.Checkbox:nth-child(3),
.ShowManagement .search .Checkbox {
    margin-left: 20px;
}

.ShowManagement .buttons-wrapper {
    justify-content: space-between;
    align-items: center;
    justify-items: end;
    width: 315px;
    float: right;
}

.ShowManagement .mini-date.small-date-field {
    width: 120px;
    margin-left: 6px;
}

.ShowManagement .d-flex button.add,
.ShowManagement .d-flex div.theme-select {
    margin: 0;
}

.ShowManagement .search ,
.ShowManagement .dates ,
.ShowManagement .buttons {
    position: relative;
    min-height: 1px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    float: left;
}

.ShowManagement .Button > button.add  {
    width: 149.5px
}

.ShowManagement .Button > button.add > .text:after {
    width: 25px;
    height: 25px;
    background: url(../../themes/default/Form/images/ico-more.svg)  no-repeat scroll 10px 2px !important;
}

.ShowManagement .controls-container > .search {
    width: calc(100vw - 850px);
}

.ShowManagement .controls-container .dates  {
    width: 815px !important;
    margin-right: 15px;
}

.ShowManagement .controls-container .buttons {
    width: 315px;
}

.Shows-list-container > table th {
    text-transform: capitalize;
}

@media only screen and ( min-width: 890px ) {
    .ShowManagement .controls-container > div {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

@media only screen and ( max-width: 1322px ) {
    .ShowManagement .controls-container > .search {
        width: 51.99%;
    }
    .ShowManagement .controls-container .dates  {
        width: 47.99% !important;
        margin: 0;
        justify-content: start;
    }
    .ShowManagement .controls-container .buttons {
        width: 100%;
        margin-top: 15px;
    }
}

@media only screen and ( max-width: 1068px ) {
    
    .ShowManagement .controls-container > .search ,
    .ShowManagement .controls-container .dates ,
    .ShowManagement .controls-container .buttons {
        width: 100% !important;
        justify-content: start;
        margin-bottom: 15px;
        margin-top: 0;
        text-align: right;
    }
    
    .ShowManagement .controls-container .buttons {
        margin-bottom: 0;
    }
    
    .ShowManagement .controls-container .dates  {
        margin-bottom: 15px;
    }
}

.ShowManagement .controls-container .search > .Search .input input,
.ShowManagement .controls-container .search > .Search {
    width: calc(100% - 326px) !important;
}

.ShowManagement .date-picker input {
    margin-bottom: 0!important;
}
.ShowManagement .controls-container .Search .Input input {
    margin-bottom: 0!important;
}

.ShowManagement .Shows-list-container .List tbody tr td:nth-child(8),
.ShowManagement .ex-show-id,
.ShowManagement .tickets-Count {
    text-align: right;
}

.ShowManagement .expanding-card-button {
    position: absolute;
    left: 326px;
    width: inherit;
    top: 13px;
}

@media all and (max-width:991px) {
    .ShowManagement .controls-container {
        margin-bottom: 15px !important;
    }
    .ShowManagement .controls-container .search > .Search .input input,
    .ShowManagement .controls-container .search > .Search {
        width: 100% !important;
    }
    .ShowManagement .controls-container .search .toggle-container,
    .ShowManagement .controls-container .dates {
        display: none
    }
    .Shows-list-container {
        max-width: 100%;
        overflow: auto;
    }
    .Shows-list-container > table {
        width: auto;
        min-width: 100%;
    }
    .Shows-list-container > table th:nth-child(8) {
        min-width: 190px;
    }
    .ShowManagement #expanding-card-container,
    .ShowManagement .expanding-card-button {
        display: none;
    }
    .ShowManagement .controls-container .buttons {
        margin-top: 15px;
        margin-bottom: 15px;
        justify-content: end;
    }

}

.ShowManagement .expanding-card-body .dates .toggle-container:first-child .switch {
    margin-left: 0;
}
.ShowManagement .expanding-card-body select {
    width: 260px;
}
.ShowManagement .expanding-card-body .dates .toggle-container:nth-child(2) {
    margin-left: 10px;
}