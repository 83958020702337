.fa-wifi {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    color: var(--black-color);
}

.wifi.weak path:nth-child(2),
.wifi.weak path:nth-child(3),
.wifi.weak path:nth-child(4) {
    stroke: var(--gray-light-color);
    /* animation: signal 3s ease-in-out infinite; */
}

.wifi.normal path:nth-child(3),
.wifi.normal path:nth-child(4)  {
    stroke: var(--gray-light-color);
    /* animation: signal 3s ease-in-out infinite; */
}

.wifi.good path:nth-child(4) {
    stroke: var(--gray-light-color);
    /* animation: signal 3s ease-in-out infinite; */
}

/* @keyframes signal {
    0% {
        stroke: var(--gray-light-color);
    }
    90% {
        stroke: var(--gray-light-color);
    }
    100% {
        stroke: var(--white-color);
    }
} */

/* .away .wifi path:nth-child(1) {
    fill: var(--gray-light-color);
}
.away .wifi path {
    stroke: var(--gray-light-color);
    animation: unset !important
} */