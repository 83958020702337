@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400..900&display=swap');
@import "../src/assets/Style_Override/Override.css";
/*
HTML5 Reset Stylesheet
*/

h1, h2, h3, h4, h5, h6 {
  padding:0;
  border:0;
  outline:0;
  vertical-align:middle;
  background:transparent;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
html, body, div, span, object, iframe,
p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  padding:0;
  border:0;
  outline:0;
  vertical-align:middle;
  background:transparent;
}

body {
  line-height: 1.42857143;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
  display:block;
}

nav ul {
  list-style:none;
}

blockquote, q {
  quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content:'';
  content:none;
}

a {
  margin:0;
  padding:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
  color :var(--primary-color) !important;
  text-decoration: none !important;
}

ins {
  background-color:#ff9;
  color:#000;
  text-decoration:none;
}

mark {
  background-color:#ff9;
  color:#000;
  font-style:italic;
  font-weight:bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom:1px dotted;
  cursor:help;
}

table {
  border-collapse:collapse;
  border-spacing:0;
}

hr {
  display:block;
  height:1px;
  border:0;
  border-top:1px solid #cccccc;
  margin:1em 0;
  padding:0;
}

input, select {
  vertical-align:middle;
}

/*
End of HTML5 Reset Stylesheet
*/

/*
Colors
*/

:root {
  
  --pink-rgb: 229, 0, 125;
  --pink: #E5007D;
  --dark-pink-rgb: 178,1,98;
  --dark-pink: #B20162;
  
  --teal-rgb: 0, 156, 155;
  --teal: #009c9b;
  --dark-teal-rgb: 2, 73, 73;
  --dark-teal: #024949;

  --purple-rgb: 132, 2, 101;
  --purple: #840265;
  --dark-purple-rgb: 96, 2, 73;
  --dark-purple: #600249;
  
  --primary-color-rgb: var(--pink-rgb);
  --primary-color: var(--pink);
  --primary-dark-color-rgb: var(--dark-pink-rgb);
  --primary-dark-color: var(--dark-pink);
  
  --primary-color-20: rgba(var(--primary-color-rgb), 0.2);
  --primary-dark-color-20: rgba(var(--primary-dark-color-rgb), 0.2);
  
  --warning-red: #d32f2f;
  
  /* fill: rgba(229, 0, 125, 0.20); */
  
  /* white */
  --white-color: #ffffff;
  --secondary-white-color: var(--white-color); 

  /* grey */
  /* Used for borders: tables, cards, panels, etc */
  --grey-300: #DEE3ED; 
  --secondary-grey-light-color: #cfcfcf;
  --disabled-content-color: #CFCFCF;
  --gray-light-color: var(--grey-300, #cfcfcf);
  --table-border-color: #f0f0f0;
  --secondary-grey-dark-color: #555555;
  --secondary-grey-lightest: #e5e5e5;
  --secondary-slate-grey-color: #626769;
  --disabled-background-gradient-start-color: #E8E8E8;
  --disabled-background-gradient-end-color: #E5E5E5;
  --gray-lightest-color: rgba(255, 255, 255, 0.4);

  /* blue */
  --primary-ice-blue-color: #f5fcff;
  --primary-cloudy-blue-color: var(--grey-300, #d4d9e2);
  --shadow-color: #f2f4f8;
  --blue-color: #1976D2;

  /* red */
  --highlight-pink-light-color: #ffe4e4;
  --red-color: #ff0000;
  --highlight-red-color: #d50101;
  --social-pale-red-color: #e24c3d;
  --error-color: #e57373;

  /* black */
  --black-color: #000000;

  /* green */
  --social-algae-green-color: #25d366;
  --highlight-kermit-green-color: #48c000;
  --highlight-green-light-color: #edffe2;
  --green-color: #388E3C;

  /* yellow */
  --highlight-pale-color: #fff0d0;
  --yelow-color: #f9aa00;
  --yellow-icon-color: #ffbd3f;

  /* orange */
  --social-orange-yellow: #ffa800;
  --orange-color: #FF5722;

  --disabled: repeating-linear-gradient( -45deg, #E8E8E8, #E8E8E8),
  repeating-linear-gradient(-45deg, #E5E5E5, #E5E5E5, 2px, transparent 4px, transparent 2px) !important;

  /* Panels */
  --panels-radius-in: 0;
  --panels-radius-out: 0;

  /* Typography Variables */
  --custom-text-small: 0.694rem;

  --panel-padding: 2rem;  
}

@media (max-width: 767px) {
  :root {
    --panel-padding: 1rem; /* New value for mobile devices */
  }
}

/*
End of Colors
*/

/*
Global CSS
*/

*::-webkit-scrollbar {
  width: 0.5em;
}

*::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: var(--secondary-grey-lightest);
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: var(--primary-color);
  outline: 1px solid slategrey;
}

.error {
  color: var(--red-color);
}

body {
  font-family: 'Roboto Condensed', sans-serif !important;
  letter-spacing: 1.2px;
}

html, body {
  height: 100% !important;
}

/* <Typography> */

h1, h2, h3, h4, h5, h6 {
  font-weight: 600 !important;
}

h1 {
  margin-bottom: 30px !important;
}

.custom-text-small {
  font-size: var(--custom-text-small, 11.67px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* letter-spacing: 0.117px; */
}

.custom-text-capitalize {
  text-transform: capitalize;
}

.one-line-with-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* </Typography> */

/* <Custom Alerts> */
.custom-text-success {
  color: var(--green-color);
}

.custom-text-error {
  color: var(--red-color) !important;
}

.custom-text-info {
  color: var(--blue-color);
}

.custom-text-warning {
  color: var(--yellow-color);
}

.custom-text-attention {
  color: var(--orange-color);
}

/* </Custom Alerts> */

/* <Panels> */
.default-panel {
  padding: var(--panel-padding);

  border-radius: var(--panels-radius-out, 10px);
  border: 1px solid var(--table-border, #DEE3ED);
  background: var(--white-color, #FFF);
}

/* .panels-group-vertical {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
} */
/* </Panels> */

.disabledContent {
  color: var(--disabled-content-color);
}

.DeviceManagement .table-options >div:nth-child(2) .text-end.p-0 ,
.table-options >div:nth-child(2) .text-end.p-0 {
  display: flex !important;
  justify-content: flex-end !important;
}

.table>:not(:first-child) {
  border-top: 0 !important;
}

/*
End of Global CSS
*/
/* Start Custom tooltip */
.tooltip .tooltip-inner {
  padding: 10px 7px;
  background-color: var(--primary-color);
}
.tooltip-arrow::before {
  border-top-color: var(--primary-color) !important;
}
/* End Custom tooltip */
