.ZoneSettingsListWrapper {
    overflow-x: auto;
    margin-bottom: 20px;
}

.ZoneSettingsList {
    margin: 20px 0 !important;
}

.ZoneSettingsList thead tr th {
    white-space: normal;
}

.ZoneSettingsList tbody tr td {
    vertical-align: middle;
}

.ZoneSettingsList tbody tr td.warning {
    background-color: var(--social-orange-yellow);
    color: var(--white-color);
}

.ZoneSettingsList tbody tr td.danger {
    line-height: 17px;
    color: var(--white-color);
    background-color: var(--social-pale-red-color);
}

.ZoneSettingsList tbody tr td.clickable:hover {
    cursor: pointer;
}

.ZoneSettingsList tbody tr td:first-child {
    text-align: center;
}

.ZoneSettingsList tbody tr td label.Switch {
    margin: 5px 0 0;
}

.ZoneSettingsListWrapper::-webkit-scrollbar {
    height: 0.5em;
}

.ZoneSettingsListWrapper::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--secondary-grey-lightest);
}

.ZoneSettingsListWrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--primary-color);
}
.ZoneSettingsListWrapper .List.ZoneSettingsList.subtable {
    margin: 0 !important;
}
.ZoneSettingsListWrapper .subtable tr th:first-child {
    width: 95px;
}

.ZoneSettingsListWrapper .subtable tr td,
.ZoneSettingsListWrapper .subtable tr th:not(:first-child) {
    text-align: left;
}

.ZoneSettingsListWrapper .List.ZoneSettingsList button.medium {
    margin: 0;
    height: 30px;
}

.ZoneSettingsListWrapper .danger .download {
    display: inline-block;
}
.ZoneSettingsListWrapper .danger .download a {
    top: 11px;
    right: 30px;
    margin-right: 15px;
    position: absolute;
}

.ZoneSettingsListWrapper .tickets-without-zone-box {
    float: left;
    cursor: pointer;
    position: relative;
}

.ZoneSettingsListWrapper .tickets-without-zone-box::after,
.ZoneSettingsListWrapper .tickets-without-zone-box::before {
    content: "";
    width: 0;
    top: 0;
    height: 0;
    transition: .2s;
    bottom: calc(50% - 10px);
    position: absolute;
    transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}

.ZoneSettingsListWrapper .tickets-without-zone-box::before {
    left: 17px;
    border-left: 10px solid var(--white-color);
}
.ZoneSettingsListWrapper .tickets-without-zone-box::after {
    left: 14px;
    border-left: 10px solid var(--social-pale-red-color);
}

.ZoneSettingsListWrapper .tickets-without-zone-box.expanded::after,
.ZoneSettingsListWrapper .tickets-without-zone-box.expanded::before {
    left: 10px;
    transition: .2s;
    transform: rotateZ(90deg);
    -webkit-transform: rotateZ(90deg);
}

.ZoneSettingsListWrapper .tickets-without-zone-box.expanded::after {
    top: -2px;
}
.ZoneSettingsListWrapper .tickets-without-zone-box.expanded::before {
    top: 1px;
}