.Button > button > .text {
    text-align: center;
    vertical-align: middle;
    line-height: inherit;
    display: inline-block;
    text-transform: uppercase;
    position: relative;
    font-weight: 500;
}

.Button > button {
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin: 20px 10px 20px 0;
    height: 54px;
    object-fit: contain;
    background-color: var(--primary-color);
    color: white;
    font-size: 17px;
    font-weight: lighter;
    font-style: normal;
    border: none;
    text-transform: uppercase;
}

.Button > button.btn-secondary {
    background-color: var(--white-color);
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.Button > button.btn-secondary:hover {
    background-color: var(--primary-color-20);
}

.Button > button.reset {
    margin: 0;
}

.Button > button.link, .Button > button.link:hover {
    background-color: transparent;
    color: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.Button > button:focus {
    outline:0;
}

.Button > button.add, .Button > button.reload {
    padding-right: 40px;
}

.Button > button.add > .text:after,
.Button > button.before > .text:before {
    content: '';
    position: absolute;
    top: calc(50% - 10px);
    right: -30px;
    display: inline-block;
    margin-right: 5px;
    width: 25px;
    height: 25px;
}

.Button > button.small > .text:before, .Button > button.large > .text:before {
    right: -28px;
}

.Button > button.add > .text:after {
    content: '';
    background: url(../../images/ico-more.svg) no-repeat scroll 10px 2px;
    background-size: 15px;
}

.Button > button.reload > .text:after {
    background: url(../../images/ico-loading.svg) no-repeat scroll 10px 10px;
    background-size: 15px;
}

.Button > button:hover {
    background-color: var(--primary-dark-color);
}

.Button > button.large {
    height: 54px;
    font-size: 17px;
}

.Button > button.medium {
    height: 45px;
    font-size: 14px;
}

.Button > button.small {
    padding-top: 1px;
    height: 37px;
    font-size: 14px;
}

.Button > button.tiny {
    padding-left: 0;
    padding-right: 0;
    width: 45px;
    height: 45px;
    margin: 0;
}

.Button > button.tiny > .text > img {
    width: 20px;
}

.Button .medium.tiny .text {
    display: flex;
    justify-content: center;
}

.upload-tickets .Button .medium .text {
    display: flex !important;
    align-items: center;
}

.Button > button.cancel {
    background-color: var(--white-color) !important;
    color: var(--primary-color);
}

.Button > button.social {
    background-color: var(--social-pale-red-color) !important;
}

.Button > button.social:hover {
    background-color: var(--highlight-red-color) !important;
}

.Button > button.ghost {
    background-color: transparent;
}

.Button > button.ghost:hover {
    background-color: var(--white-color);
    color: var(--black-color);
}

.Button > button.disabled, .Button > button.disabled:hover {
    color: var(--gray-light-color);
    background:
            repeating-linear-gradient( -45deg, var(--disabled-background-gradient-start-color), var(--disabled-background-gradient-start-color)),
            repeating-linear-gradient(-45deg, var(--disabled-background-gradient-end-color), var(--disabled-background-gradient-end-color), 2px, transparent 4px, transparent 2px) !important;
    background-blend-mode: overlay;
    cursor: not-allowed;
}