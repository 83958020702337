.tiny-cards {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;
}

.tiny-card {
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    border-radius: var(--panels-radius-in, 5px);
    border: 1px solid var(--table-border, #DEE3ED);
}

.card-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
}