/* The container */
.Radio {
    display: block;
    position: relative;
    padding-left: 35px;
    height: 22px;
    margin-bottom: 12px;
    padding-top: 5px;
    cursor: pointer;
    font-size: 15px;
    font-weight: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Radio.disabled {
    cursor: not-allowed;
    color: var(--secondary-grey-light-color);
}

.Radio.disabled .checkmark {
    color: var(--secondary-grey-light-color);
    background: var(--disabled);
    background-blend-mode: overlay;
    border: solid 1px var(--primary-cloudy-blue-color);
}

/* Hide the browser's default radio button */
.Radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.Radio > .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: var(--secondary-white-color);
    border: solid 1px var(--primary-cloudy-blue-color);
    border-radius: 50%;
    font-size: 15px;
    font-weight: normal;
}

/* On mouse-over, add a grey background color */
.Radio:hover input ~ .checkmark {
    background-color: var(--secondary-white-color);
    border: solid 1px var(--secondary-grey-dark-color);
}

.Radio.disabled:hover input ~ .checkmark {
    border: solid 1px var(--primary-cloudy-blue-color);
    background: var(--disabled);
}

/* When the radio button is checked, add a blue background */
.Radio input:checked ~ .checkmark {
    background-color: var(--primary-color);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.Radio > .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.Radio input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.Radio .checkmark:after {
    content: '';
    top: 7px;
    left: 7px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: var(--secondary-white-color);
    border: solid var(--secondary-white-color);
}