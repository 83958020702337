.ZoneSettings > .Button button {
    margin: 0;
}

.ZoneSettings .filters {
    margin: 20px 0;
}

.ZoneSettings .theme-select {
    display: inline-block;
    width: 125px;
    margin-left: 10px;
}

.ZoneSettings.pagination-hidden .pagination {
    display: none;
 }