.LogSearch {
    margin: 20px 0;
}

.LogSearch > div:first-child {
    padding: 12px 0 !important;
}

.LogSearch .theme-select,
.LogSearch .Search {
    display: inline-block;
}

.LogSearch .theme-select select {
    height: 45px;
    border-right: none;
}

.LogSearch .theme-select,
.LogSearch .Search {
    width: 100%;
}

.LogSearch .Search .Input input {
    height: 45px;
    margin: 0;
    background-position: 10px 14px;
    padding-top: 13px;
    background-color: var(--white-color);
}

.LogSearch > div:last-child .Button button {
    margin: 0;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    height: 45px;
    margin-left: 20px;
}