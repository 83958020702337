img {
    cursor: pointer;
}

.copied {
    color: var(--highlight-kermit-green-color);
}

.red-text-warning {
    color: var(--red-color);
}

.ProviderManagement .table-options {
    margin-bottom: 30px;
}

.ProviderManagement .table-options .Button button {
    margin-top: 0;
    margin-bottom: 0;
}

.ProviderManagement .table-options .Button:last-child button {
    margin-right: 0;
}

.ProviderManagement .table-options .Button:last-child button img {
    width: 20px;
}

.ProviderManagement .table-options .active-providers {
    padding-top: 8px !important;
    padding-left: 50px !important;
}

.ProviderManagement .table-options .active-providers .checkbox {
    margin: 0;
}

.ProviderManagement .table-pagination {
    margin-bottom: 40px;
}

.ProviderManagement .table-pagination .Button:not(:last-child) {
    margin-right: 10px;
}

.ProviderManagement .table-pagination .Button button,
.ProviderManagement .table-pagination .Button button.current-page {
    padding: 0 !important;
}

.ProviderManagement .table-pagination .Button button.current-page {
    background-color: var(--white-color);
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
}

.ProviderManagement .provider-buttons {
    width: 45px;
}

.ProviderManagement .table-options .col-9 {
    display: flex;
    align-items: center;
}

.ProviderManagement .active-column {
    width: 92px;
}

.ProviderManagement .shows-number {
    width: 219px;
}

.ProviderManagement table td:nth-of-type(2) {
    text-align: right;
}

.ProviderManagement td.provider {
    text-align: center;
}

.ProviderManagement .modal .modal-body .switch {
    margin: 0;
}
.ProviderManagement .modal.edit-provider-dialog .modal-body > .row {
    height: 50px;
    margin-bottom: 0;
    align-items: center;
}

.edit-provider-dialog .modal-body .toggle-container .switch {
    margin: 0 !important;
}

.edit-provider-dialog .modal-body input,
.edit-provider-dialog .modal-body label {
    font-size: 16px;
    font-weight: 600;
}

.ProviderManagement .List tbody tr td:first-child {
    color: var(--primary-color);
    cursor: pointer;
}

.edit-provider-dialog .V2-provider input {
    font-size: 17px;
    font-weight: bold;
    line-height: 1.24;
}
.edit-provider-dialog .theme-select select,
.edit-provider-dialog .modal-body input,
.edit-provider-dialog .modal-body label {
    font-size: 14px;
    font-weight: normal;
}
.edit-provider-dialog .modal-body img {
    width: 22px;
    margin-left: 15px;
}
.edit-provider-dialog .modal-body .theme-select img {
    width: 8px;
}
.client-id > div:not(:first-child) {
    font-weight: 600;
}
.client-secret > div:not(:first-child) {
    font-size: 10px;
}
.client-secret .show-client-secret {
    font-size: 14px !important;
    font-weight: bold;
}
.number-of-Shows {
    margin-top:15px
}
.edit-provider-dialog .toggle-container label.switch span.slider{
    width: 45px;
    height: 20px;
}
.edit-provider-dialog .toggle-container label.switch span.slider:after {
    height: 16px;
    width: 17px;
}
.edit-provider-dialog .toggle-container label.switch.checked span.slider:after {
    left: 59%;
}
@media all and (max-width:991px) {
    .ProviderManagement .table-options {
        margin-bottom: 15px;
    }
    .ProviderManagement .table-options .active-providers {
        padding: 12px 0 !important;
    }
    .ProviderManagement .List tr th:nth-child(2),
    .ProviderManagement .List tr td:nth-child(2),
    .ProviderManagement .List tr th:nth-child(3),
    .ProviderManagement .List tr td:nth-child(3),
    .ProviderManagement .List tr th:nth-child(4),
    .ProviderManagement .List tr td:nth-child(4) {
        display: none;
    }
    .modal.edit-provider-dialog .modal-content .modal-body {
        padding: 0 15px !important;
    }
    .modal.edit-provider-dialog .modal-content .modal-body .row .col-md-2 {
        width: 16.666%;
    }
    .modal.edit-provider-dialog .modal-content .modal-body .row .col-md-3 {
        width: 25%;
    }
    .modal.edit-provider-dialog .modal-content .modal-body .row .col-md-7 {
        width: 58.333%;
    }
    .modal.edit-provider-dialog .modal-content .modal-body .row .col-md-8 {
        width: 66.666%;
    }
    .modal.edit-provider-dialog .modal-content .modal-body .row .col-md-9 {
        width: 75%;
    }
    .table-options .toggle-container {
        margin-left: 10px
    }
    .table-options .toggle-container .switch {
        margin-left: 0
    }
}
@media only screen and (min-width:300px) and (max-width:500px) {
    .client-id > div:not(:first-child) {
        font-size: 3.1vw;
    }
}

@media screen and (max-width:767px) {
    
    .table-options .toggle-container {
        margin-left: 0px
    }
}

